import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { backendBaseUrl } from "../../constants/api/backendBaseUrl";
import { jsonToJwt } from "../../lib/jwt/jsonToJwt";

interface EducationInstitutionFeaturesResponse {
  education_institution: {
    id: string;
    name: string;
    abbreviated_name: string;
    type: string;
    location_lat: number;
    location_long: number;
    state_or_province: string;
    municipality: string;
    country: string;
  };
  cpl_features: string[];
}

export const useQueryEducationInstitutionFeatures = () => {
  const handleRequest = async () => {
    const { data } = await axios.get<EducationInstitutionFeaturesResponse>(
      `${backendBaseUrl}core-plar-recommendation/education-institution/features`,
      {
        headers: {
          "Authorization": "Bearer " + jsonToJwt({}),
        }
      }
    );
    return data;
  };

  return useQuery({
    queryKey: ["education-institution-features"],
    queryFn: handleRequest,
    retry: 1,
  });
};