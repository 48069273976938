import "./applicants.scss";
import moment from "moment";
import { useQueryApplicants } from "../../../api/admin/useQueryApplicants";
import Search from "../../../components/UI/Search/Search";
import { useContext, useEffect, useState } from "react";
import ComplexSearch from "../../../components/UI/ComplexSearch/ComplexSearch";
import { useQueryPrograms } from "../../../api/admin/useQueryPrograms";
import UserProfile from "../../../components/UI/Profile/UserProfile";
import ProgressChiclet from "../../../components/UI/Chiclets/ProgressChiclet/ProgressChiclet";
import { ChicletTypes } from "../../../constants/ui/types/ChicletTypes";
import Loading from "../../../components/UI/Loading/Loading";
import { useQueryClient } from "@tanstack/react-query";
import { ISortingOption } from "../../../components/UI/SearchAndSort/SearchAndSort";
import { applicantsSortingOptions } from "../../../constants/ui/sortingOptions";
import { IProgram } from "../../../constants/types/IProgram";
import { useNavigate } from "react-router-dom";
import Pagination from "../../assessments/components/EmployeeTablePagination/Pagination";
import DocumentRequestButton from "../AdminDocumentRequest/DocumentRequestButton/DocumentRequestButton";
import { useQueryAdmins } from "../../../api/admin/useQueryAdmins";
import { IUser, UserContext } from "../../../hooks/Context/UserContext";
import { PermissionType } from "../../../constants/types/PermissionType";
import FilterIcon from "../../../assets/icons/UI/icons/filter.svg";
import { DropdownOption } from "../../../components/UI/SearchAndSort/DropdownWithIcon/Sorting";
import NavigationTabs from "../../../components/UI/NavigationTabs/NavigationTabs";
import DateRangeFilter from "../../../components/UI/DateRangeFilter/DateRangeFilter";
import CheckboxFilter from "../../../components/UI/CheckboxFilter/CheckboxFilter";
import { useQueryInactiveApplicants } from "../../../api/admin/useQueryInactiveApplicants";
import { UploadSimple, WarningCircle } from "@phosphor-icons/react";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import {
	InactiveUsersExportParams,
	useInactiveUsersExport,
} from "../../../api/admin/useInactiveUsersExport";
import {useQueryCSV} from "../../../api/admin/useQueryCSV";

interface DateRangeValue {
	firstInteraction: DateRange;
	lastInteraction: DateRange;
	activeInteraction: "first" | "last" | null;
}

interface DateRange {
	startDate: moment.Moment | null;
	endDate: moment.Moment | null;
}

interface FilterOption {
	label: string;
	value: string;
}

const dropOffOptions = [
	{ label: "Payment", value: "payment" },
	{ label: "Profile review", value: "profile_review" },
	{ label: "Recommendation review", value: "recommendation_review" },
	{ label: "Selected course", value: "selected_course" },
];

const ActiveApplicantsTable = ({
	searchParam,
	page,
	sortingValue,
	filterValue,
	hideReviewComplete,
	assignedTo,
	onPageChange,
	navigate,
	queryClient
}: {
	searchParam: string;
	page: number;
	sortingValue: ISortingOption;
	filterValue: ISortingOption[];
	hideReviewComplete: boolean;
	assignedTo: IUser;
	onPageChange: (page: number) => void;
	navigate: (path: string) => void;
	queryClient: any;
}) => {
	const [user, setUser] = useContext(UserContext);

	useEffect(() => {
		if (user.notification_id) {
		  queryClient.invalidateQueries({
			queryKey: ["applicants"],
			exact: true,
		  });
		}
	  }, [user.notification_id, queryClient]);

	const { data: applicants, isLoading } = useQueryApplicants(
		{
			assigned_to: assignedTo?.id,
			hide_completed: hideReviewComplete,
			ordering: sortingValue.value,
			search_term: searchParam,
			current_page: page,
			program_ids:
				filterValue.length > 0
					? filterValue.map((item) => item.value)
					: [],
			notification_id: user.notification_id
		},
		{
			//@ts-ignore
			keepPreviousData: true,
			staleTime: 0,
			onSuccess: () => {
				if (user.notification_id) {
					setTimeout(() => {
						setUser({ ...user, notification_id: undefined });
					}, 2000);
				}
			  },
		}
	);

	if (isLoading) return <Loading loading />;

	const getApplicantStatus = (status: string): ChicletTypes => {
		switch (status) {
			case "Not started":
				return ChicletTypes.Not_started;
			case "Review in progress":
				return ChicletTypes.In_progress;
			case "Review completed":
				return ChicletTypes.Completed;
			default:
				return ChicletTypes.Not_started;
		}
	};

	return (
		<>
			<table className="applicants-table">
				<thead>
					<tr>
						<th scope="col">Applicant</th>
						<th scope="col">Application date</th>
						<th scope="col">Review status</th>
						<th scope="col">Assigned to</th>
						<th scope="col">Request documents</th>
					</tr>
				</thead>
				<tbody>
					{applicants?.data?.map((item: any) => (
						<tr 
							key={item.id} 
							onClick={() => navigate(`/cpl-admin/${item.id}`)}
							className={item.highlight ? 'highlight-row' : ''}
						>
							<td>
								<UserProfile
									name={{
										first_name: item.first_name,
										last_name: item.last_name,
									}}
									size="small"
									showText
								/>
							</td>
							<td>
								<div className="applicants-table-created-at">
									{item.created_at}
									<br />({item.created_ago_str.toLowerCase()})
								</div>
							</td>
							<td>
								<ProgressChiclet
									type={getApplicantStatus(item.status)}
									text={item.status}
								/>
							</td>
							<td>{item.assigned_to}</td>
							<td>
								<DocumentRequestButton
									applicantId={item.id}
									disabled={!item.can_request_documents}
									documentStats={item.document_request_stats}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
			{Number(applicants?.total_pages) > 1 && (
				<Pagination
				total={Number(applicants?.total_pages)}
				currentPage={page}
				onPageChange={(newPage) => {
				  queryClient.removeQueries({
					queryKey: ["applicants"],
					exact: true
				  });
				  onPageChange(newPage);
				  window.scroll(0, 0);
				}}
			  />
			)}
		</>
	);
};

const InactiveApplicantsTable = ({
	searchParam,
	page,
	dateRange,
	dropOffFilter,
	onPageChange,
	navigate,
	onDateRangeChange,
	onDropOffFilterChange,
	queryClient,
}: {
	searchParam: string;
	page: number;
	dateRange: DateRangeValue;
	dropOffFilter: FilterOption[];
	onPageChange: (page: number) => void;
	navigate: (path: string) => void;
	onDateRangeChange: (newRange: DateRangeValue) => void;
	onDropOffFilterChange: (newFilter: FilterOption[]) => void;
	queryClient: any;
}) => {
	const { data: inactiveApplicants, isLoading } = useQueryInactiveApplicants(
		{
			filter_action_type: dropOffFilter.map((filter) => filter.value),
			filter_date_type:
				dateRange.activeInteraction === "first"
					? "first_interaction_date"
					: dateRange.activeInteraction === "last"
					? "last_interaction_date"
					: undefined,
			start_date: dateRange.activeInteraction
				? dateRange[
						`${dateRange.activeInteraction}Interaction`
				  ].startDate?.format("YYYY-MM-DD")
				: undefined,
			end_date: dateRange.activeInteraction
				? dateRange[
						`${dateRange.activeInteraction}Interaction`
				  ].endDate?.format("YYYY-MM-DD")
				: undefined,
			search_term: searchParam,
			current_page: page,
			items_per_page: 10,
		},
		{
			//@ts-ignore
			keepPreviousData: false,
			staleTime: 0,
			cacheTime: 0,
			refetchOnWindowFocus: false,
		}
	);

	const handlePageChange = (newPage: number) => {
		queryClient.removeQueries(["inactive-applicants"]);
		onPageChange(newPage);
		window.scroll(0, 0);
	};
	const [showNoPortfolioAlert, setShowNoPortfolioAlert] = useState(false);

	const handleRowClick = (item: any) => {
		if (item.id) {
			navigate(`/cpl-admin/${item.id}`);
		} else {
			setShowNoPortfolioAlert(true);
		}
	};

	const { downloadInactiveUsers } = useInactiveUsersExport();

	const handleExport = async () => {
		// @ts-ignore
		const params: InactiveUsersExportParams = {
			filter_action_type: dropOffFilter.map((filter) => filter.value),
			filter_date_type:
				dateRange.activeInteraction === "first"
					? "first_interaction_date"
					: dateRange.activeInteraction === "last"
					? "last_interaction_date"
					: undefined,
			start_date: dateRange.activeInteraction
				? dateRange[
						`${dateRange.activeInteraction}Interaction`
				  ].startDate?.format("YYYY-MM-DD")
				: undefined,
			end_date: dateRange.activeInteraction
				? dateRange[
						`${dateRange.activeInteraction}Interaction`
				  ].endDate?.format("YYYY-MM-DD")
				: undefined,
			search_term: searchParam,
		};

		await downloadInactiveUsers(params);
	};

	if (isLoading) return <Loading loading />;

	return (
		<>
			<div className="filters-and-stats">
				<div className="filters-container">
					<DateRangeFilter
						value={dateRange}
						onChange={(newRange: DateRangeValue) => {
							onDateRangeChange(newRange);
							if (newRange.activeInteraction === null) {
								queryClient.invalidateQueries({
									queryKey: ["inactive-applicants"],
									exact: true,
								});
							}
						}}
						minDate={moment().subtract(1, "year")}
						maxDate={moment()}
					/>
					<CheckboxFilter
						title="Drop off area"
						options={dropOffOptions}
						value={dropOffFilter}
						onChange={(newFilter: FilterOption[]) => {
							onDropOffFilterChange(newFilter);
							onPageChange(1);
							queryClient.invalidateQueries({
								queryKey: ["inactive-applicants"],
								exact: true,
							});
						}}
					/>
				</div>
				<div className="stats-container">
					<div className="user-count">
						{inactiveApplicants?.total_filtered_inactive_users} out of{" "}
						{inactiveApplicants?.total_inactive_users}
					</div>
					<span className="export-button" onClick={handleExport}>
						<UploadSimple size={22} />
						Export all
					</span>
				</div>
			</div>
			<table className="applicants-table">
				<thead>
					<tr>
						<th scope="col">Applicant</th>
						<th scope="col">Email</th>
						<th scope="col">First interaction</th>
						<th scope="col">Drop off area</th>
						<th scope="col">Last interaction</th>
					</tr>
				</thead>
				<tbody>
					{inactiveApplicants?.data?.map((item: any) => (
						<tr key={item.id} onClick={() => handleRowClick(item)}>
							<td>
								<UserProfile
									name={{
										first_name: item.first_name,
										last_name: item.last_name,
									}}
									size="small"
									showText
								/>
							</td>
							<td>{item.email}</td>
							<td className="applicants-table-first-interaction-cell">
								<div className="applicants-table-first-interaction-cell-date">
									{moment(item.first_interaction_date).format("MM/DD/YYYY")}
								</div>
								<div className="applicants-table-first-interaction-cell-ago-text">
									({item.first_interaction_date_ago_str})
								</div>
							</td>
							<td>{item.last_action}</td>
							<td>{moment(item.last_interaction_date).format("MM/DD/YYYY")}</td>
						</tr>
					))}
				</tbody>
			</table>
			{Number(inactiveApplicants?.total_pages) > 1 && (
				<Pagination
					total={Number(inactiveApplicants?.total_pages)}
					currentPage={page}
					onPageChange={handlePageChange}
				/>
			)}
			<AlertModal
				open={showNoPortfolioAlert}
				icon={<WarningCircle size={56} color="#212121" />}
				onClose={() => setShowNoPortfolioAlert(false)}
				title="Incomplete Profile"
				text="This applicant did not submit their profile information"
				onProceed={() => setShowNoPortfolioAlert(false)}
				proceedText="Return to Applicant list"
			/>
		</>
	);
};

const ApplicantsList = () => {
	const [user] = useContext(UserContext);
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState<"active" | "inactive">("active");
	const [activeSearchParam, setActiveSearchParam] = useState<string>("");
	const [activePage, setActivePage] = useState<number>(1);
	const [sortingValue, setSortingValue] = useState<ISortingOption>(
		applicantsSortingOptions[2]
	);
	const [filterValue, setFilterValue] = useState<ISortingOption[]>([]);
	const [hideReviewComplete, setHideReviewComplete] = useState<boolean>(false);
	const [assignedTo, setAssignedTo] = useState<IUser>({});
	const [inactiveSearchParam, setInactiveSearchParam] = useState<string>("");
	const [inactivePage, setInactivePage] = useState<number>(1);
	const [dropOffFilter, setDropOffFilter] = useState<FilterOption[]>([]);
	const [dateRange, setDateRange] = useState<DateRangeValue>({
		firstInteraction: { startDate: null, endDate: null },
		lastInteraction: { startDate: null, endDate: null },
		activeInteraction: null,
	});

	const { data: programs, isLoading: programsIsLoading } = useQueryPrograms({
		name_only: true,
	});

	const { data: adminsList, refetch: refetchAdminList } = useQueryAdmins();
	const {downloadCsvReport} = useQueryCSV();

	useEffect(() => {
		if (user.permission_groups?.includes(PermissionType.cpl_super_admin)) {
			refetchAdminList();
		}
	}, [user, refetchAdminList]);

	useEffect(() => {
		if (
			activeTab === "active" &&
			(activeSearchParam.length > 2 || activeSearchParam.length === 0)
		) {
			setActivePage(1);
			queryClient.invalidateQueries({
				queryKey: ["applicants"],
				exact: true,
			});
		}
	}, [activeSearchParam, activeTab]);

	useEffect(() => {
		if (activeTab === "active") {
			queryClient.invalidateQueries({
				queryKey: ["applicants"],
				exact: true,
			});
		}
	}, [activePage, activeTab]);

	useEffect(() => {
		if (activeTab === "active") {
			setActivePage(1);
			queryClient.invalidateQueries({
				queryKey: ["applicants"],
				exact: true,
			});
		}
	}, [sortingValue, filterValue, assignedTo, hideReviewComplete, activeTab]);

	useEffect(() => {
		if (
			activeTab === "inactive" &&
			(inactiveSearchParam.length > 2 || inactiveSearchParam.length === 0)
		) {
			setInactivePage(1);
			queryClient.invalidateQueries({
				queryKey: ["inactive-applicants"],
				exact: true,
			});
		}
	}, [inactiveSearchParam, activeTab]);

	useEffect(() => {
		if (activeTab === "inactive") {
			queryClient.invalidateQueries({
				queryKey: ["inactive-applicants"],
				exact: true,
			});
		}
	}, [inactivePage, activeTab]);

	useEffect(() => {
		if (
			activeTab === "inactive" &&
			(dateRange.activeInteraction || dropOffFilter.length > 0)
		) {
			setInactivePage(1);
			queryClient.invalidateQueries({
				queryKey: ["inactive-applicants"],
				exact: true,
			});
		}
	}, [dateRange, dropOffFilter, activeTab]);

	if (programsIsLoading) {
		return <Loading loading />;
	}

	const handleTabChange = (newTab: "active" | "inactive") => {
		setActiveTab(newTab);
		if (newTab === "active") {
			setActiveSearchParam("");
			setActivePage(1);
		} else {
			setInactiveSearchParam("");
			setInactivePage(1);
		}
	};

	return (
		<div className="applicants">
			<div className="applicants-title">Applicants</div>

			{user.permission_groups?.includes(PermissionType.cpl_super_admin) && (
				<NavigationTabs
					tabs={[
						{
							name: "Active applicants",
							onClick: () => handleTabChange("active"),
							active: activeTab === "active",
						},
						{
							name: "Inactive applicants",
							onClick: () => handleTabChange("inactive"),
							active: activeTab === "inactive",
						},
					]}
				/>
			)}

			<div className="applicants-search">
				<Search
					value={
						activeTab === "active" ? activeSearchParam : inactiveSearchParam
					}
					onChange={(e) =>
						activeTab === "active"
							? setActiveSearchParam(e.target.value)
							: setInactiveSearchParam(e.target.value)
					}
					placeholder="Search Applicants"
				/>
			</div>

			{activeTab === "active" ? (
				<>
					<ComplexSearch
						filter={{
							name: "Programs",
							value: filterValue,
							options:
								programs?.length > 0
									? programs.map((item: IProgram) => ({
											label: item.name,
											value: item.id,
									  }))
									: [],
							onChange: setFilterValue,
						}}
						sorting={{
							options: applicantsSortingOptions,
							value: sortingValue,
							onChange: setSortingValue,
						}}
						toggle={
							!user.permission_groups?.includes(PermissionType.cpl_super_admin)
								? [
										{
											active: hideReviewComplete,
											onToggle: () =>
												setHideReviewComplete(!hideReviewComplete),
											label: "Hide review completed",
										},
										{
											active: assignedTo.id === user.id,
											onToggle: () =>
												setAssignedTo(assignedTo.id === user.id ? {} : user),
											label: "Assigned to me",
										},
								  ]
								: [
										{
											active: hideReviewComplete,
											onToggle: () =>
												setHideReviewComplete(!hideReviewComplete),
											label: "Hide review completed",
										},
								  ]
						}
						dropdown={
							user.permission_groups?.includes(PermissionType.cpl_super_admin)
								? {
										options: adminsList
											? [
													{
														label: "Assigned to me",
														value: {
															first_name: "Assigned to me",
															last_name: "",
															id: user.id,
														},
													},
													...adminsList.map((item: IUser) => ({
														label: `${item?.first_name} ${item?.last_name}`,
														value: item,
													})),
													{
														label: "No assignee",
														value: {
															first_name: "No assignee",
															last_name: "",
															id: "none",
														},
													},
											  ]
											: [],
										value: {
											label: assignedTo.first_name
												? `${assignedTo.first_name} ${assignedTo.last_name}`
												: "Assignee",
											value: assignedTo,
										},
										onChange: (newActive: DropdownOption) =>
											setAssignedTo(newActive.value),
										icon: FilterIcon,
								  }
								: undefined
						}
						exportButton={{
							label: 'Export All',
							onClick: downloadCsvReport
						}}
					/>
					<ActiveApplicantsTable
						searchParam={activeSearchParam}
						page={activePage}
						sortingValue={sortingValue}
						filterValue={filterValue}
						hideReviewComplete={hideReviewComplete}
						assignedTo={assignedTo}
						onPageChange={(page) => {
							setActivePage(page);
							window.scroll(0, 0);
						}}
						navigate={navigate}
						queryClient={queryClient}
					/>
				</>
			) : (
				<InactiveApplicantsTable
					searchParam={inactiveSearchParam}
					page={inactivePage}
					dateRange={dateRange}
					dropOffFilter={dropOffFilter}
					onPageChange={(page) => {
						setInactivePage(page);
						window.scroll(0, 0);
					}}
					navigate={navigate}
					onDateRangeChange={setDateRange}
					onDropOffFilterChange={setDropOffFilter}
					queryClient={queryClient}
				/>
			)}
		</div>
	);
};

export default ApplicantsList;
