import './unsubscribe.scss';
import {useEmailUnsubscribe} from "../../../api/email/useEmailUnsubscribe";
import {useEffect, useState} from "react";
import {useGetEmailUnsubscribeQuiz} from "../../../api/email/useGetEmailUnsubscribeQuiz";
import {useEmailUnsubscribeQuiz} from "../../../api/email/useEmailUnsubscribeQuiz";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import LoginHeader from "../../../components/Headers/LoginHeader/LoginHeader";
import {useEmailSubscribe} from "../../../api/email/useEmailSubscribe";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../constants/ui/types/ButtonTypes";

const EmailUnsubscribe = () => {
  const navigate = useNavigate();
  const [token, setToken] = useState<string>('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const {mutate: subscribe, isSuccess: subscribeSuccess} = useEmailSubscribe();
  const {mutate: unsubscribe} = useEmailUnsubscribe();
  const {mutate: getQuiz, data: quizOptions} = useGetEmailUnsubscribeQuiz();
  const {mutate: postQuiz, isSuccess} = useEmailUnsubscribeQuiz();

  const handleOption = (id: string) => {
    let filteredOptions = [...selectedOptions].filter(item => item !== id);
    if (filteredOptions.length === selectedOptions.length) {
      setSelectedOptions([...selectedOptions, id])
    } else {
      setSelectedOptions(filteredOptions);
    }
  }
  const handlePostQuiz = () => {
    if (selectedOptions.length > 0) {
      postQuiz({id: quizOptions.id, options: selectedOptions, token});
    } else {
      toast.error('Please choose at least 1 option.')
    }
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(decodeURI(window.location.search));
    const tokenParam = urlParams.get('token');
    if (tokenParam) {
      setToken(tokenParam);
      unsubscribe({token: tokenParam});
      getQuiz({token: tokenParam});
    }
  }, [])

  useEffect(() => {
    if (isSuccess || subscribeSuccess) {
      toast.success(subscribeSuccess
        ? 'Email was successfully subscribed!'
        : 'Thank you for your feedback!'
      );
      navigate('/cpl-applicant');
    }
  }, [isSuccess, subscribeSuccess])

  return <div className={'unsubscribe'}>
    <LoginHeader/>
    <div className={'unsubscribe-title'}>
      {'You have successfully unsubscribed from our email list. '}
      <span className={'unsubscribe-title-link'} onClick={() => subscribe({token})}>
        Click to subscribe
      </span>
    </div>

    <div className={'unsubscribe-body'}>
      <div className={'unsubscribe-body-title'}>
        Your feedback matters!
      </div>
      <div className={'unsubscribe-body-text'}>
        {'We’re sorry to see that you are no longer interested in receiving emails from us. '}
        <strong>
          Please take a moment and let us know what made you unsubscribe, so that we can set it right.
        </strong>
      </div>
      {
        quizOptions && quizOptions.options.map((item: {id: string, text: string}, index: number) =>
          <Checkbox
            handleClick={() => handleOption(item.id)}
            active={selectedOptions.includes(item.id)}
            text={item.text}
          />
        )
      }
      <div className={'unsubscribe-body-footer'}>
        <ButtonKMQ
          onClick={handlePostQuiz}
          type={ButtonTypes.Tertiary}
        >
          Submit
        </ButtonKMQ>
      </div>
    </div>
  </div>
}

export default EmailUnsubscribe