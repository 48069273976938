import {createContext, Dispatch} from "react";
import {Resume} from "../../lib/resume-parser/redux/types";


export const ResumeContext = createContext<[Resume, Dispatch<Resume>]>(
  [
    {
      basic_info: {},
      work_experience: [{
        company: '',
        job_title: '',
        job_summary: ''
      }],
      education: [{
        program: ''
      }],
      certificates:[]
    },
    () => {
    }]
);