import './identity-providers.scss';
import {useQueryIdentityProviders} from "../../../api/login/useQueryIdentityProviders";
import {useQueryIdentityProvider} from "../../../api/login/useQueryIdentityProvider";
import {useNavigate} from "react-router-dom";
import React, {useState, useEffect} from "react";
import ButtonKMQWithIconText from "../../../components/UI/Button/ButtonKMQWithIconText";

interface IIdentityProvider {
  id: string,
  display_name: string,
  button_logo: string,
}

const IdentityProviders = () => {
  const [identityProviderId, setIdentityProviderId] = useState<string>('');
  const {data} = useQueryIdentityProviders();
  const {data: redirectUrl, isSuccess} = useQueryIdentityProvider({id: identityProviderId});

  const handleSubmit = async (identityProviderId: string) => {
    setIdentityProviderId('');
    setIdentityProviderId(identityProviderId);
  }

  useEffect(() => {
    if (isSuccess && redirectUrl && identityProviderId !== '') {
      window.location.href = redirectUrl;
    }
  }, [isSuccess, redirectUrl]);

  if (!data) {
    return null;
  }

  return <div className={'identity-providers'}>
    {
      data && data.map((item: IIdentityProvider, index: number) =>
        <div key={index}>
          <ButtonKMQWithIconText
            onClick={() => handleSubmit(item.id)}
          >
            <img
              src={item.button_logo}
              alt={item.display_name}
              className={'identity-providers-logo'}
            />
            <span className={'identity-providers-text'}>
                Continue with &nbsp;
              {item.display_name}
              </span>
          </ButtonKMQWithIconText>
        </div>
      )
    }
  </div>
}

export default IdentityProviders;