import {createContext, Dispatch} from "react";
import {PermissionType} from "../../constants/types/PermissionType";

export interface IUser {
  email?: string,
  user_id?: string,
  id?: string,
  isSoft?: boolean,
  permission_groups?: PermissionType[],
  permissions?: string[],
  first_name?: string,
  last_name?: string,
  portfolio_id?: string,
  notification_id?: string
}

export const UserContext = createContext<[IUser, Dispatch<IUser>]>(
  [
    {},
    () => {}
  ]
);