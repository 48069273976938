import React, {useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {UserContext} from "../../../hooks/Context/UserContext";
import Loading from "../../UI/Loading/Loading";
import {useToken} from "../../../hooks/jwt/useToken";
import {useQueryUser} from "../../../api/admin/useQueryAdmin";
import {PermissionType} from "../../../constants/types/PermissionType";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {Helmet} from "react-helmet";
import {useGetSubdomainInfo} from "../../../api/general/useGetSubdomainInfo";

const AuthLayout = ({children}: { children: React.ReactNode }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useContext(UserContext);
  const [portfolio] = useContext(PortfolioContext);

  const {token, isLoading} = useToken();
  const {data, refetch} = useQueryUser();
  const {data: subdomainInfo} = useGetSubdomainInfo();

  useEffect(() => {
    if (!localStorage.getItem('refresh') && !token && !isLoading && (location.pathname !== '/cpl-applicant' && !location.pathname.includes('/login') && !location.pathname.includes('/email'))) {
      navigate('/cpl-applicant');
    } else if (!user.isSoft && token && !isLoading && !user?.permission_groups) {
      refetch();
    }
  }, [location, token, isLoading])

  useEffect(() => {
    if (data) {
      setUser(data)
    }
  }, [data])

  useEffect(() => {
    if (location.pathname === '/' || location.pathname === '') {
      if (token) {
        if (user.permission_groups?.includes(PermissionType.assessments_user)) {
          navigate('/assessments/dashboard');
        } else if (user.permission_groups?.includes(PermissionType.cpl_admin)) {
          navigate('/cpl-admin');
        } else if (user.permission_groups?.includes(PermissionType.cpl_applicant)) {
          navigate('/cpl-applicant');
        } else if (user.permission_groups?.includes(PermissionType.assessments_admin)) {
          navigate('/assessments/employees');
        }
      } else {
        navigate('/cpl-applicant');
      }
    }
  }, [location, navigate, token, user, portfolio])


  if (token || location.pathname.includes('/login') || location.pathname === '/cpl-applicant' || location.pathname === '/' || location.pathname.includes('/email')) {
    return <div>
      {subdomainInfo
        ?
        <Helmet>
          <meta charSet="utf-8"/>
          <title>{subdomainInfo?.title_tag}</title>
          <meta
            name="description"
            content={subdomainInfo?.title_content}
          />
          <link rel="icon" type="image/png" href={subdomainInfo?.favicon_url} sizes="16x16"/>
        </Helmet>
        : null
      }

      {children}
    </div>
  }

  if (isLoading) {
    return <Loading loading/>
  }

  // implement 404 page
  return <div>Not found</div>
}
export default AuthLayout;