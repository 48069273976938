import "./header.scss";
import LogoKMQ from "../../../assets/logo/KnowMeQ_logo.svg";
import UserProfile from "../../UI/Profile/UserProfile";
import { useLocation, useNavigate } from "react-router-dom";
import { useQueryUser } from "../../../api/admin/useQueryAdmin";
import { useContext, useEffect, useState } from "react";
import AlertModal from "../../UI/Modals/AlertModal/AlertModal";
import {
  Bell,
  CheckCircle,
  List,
  SignOut,
  WarningCircle,
} from "@phosphor-icons/react";
import {
  PortfolioContext,
  PortfolioStatusTypes,
} from "../../../hooks/Context/PortfolioContext";
import { useGetSubdomainInfo } from "../../../api/general/useGetSubdomainInfo";
import Notifications from "../../NotificationSystem/Notifications";
import { useNotifications } from "../../../api/notifications/useNotifications";

const Header = ({ toggleMenu }: { toggleMenu: () => void }) => {
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = useQueryUser();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showEditResume, setshowEditResume] = useState(false);
  const [showStartOver, setshowStartOver] = useState(false);
  const [logoutModalOpen, setLogoutModalOpen] = useState<boolean>(false);
  const { data: subdomainInfo } = useGetSubdomainInfo();

  const { unseenCount, resetUnseenCount } = useNotifications();
  const [showNotifications, setShowNotifications] = useState(false);

  useEffect(() => {
    if (showNotifications) {
      setShowNotifications(false);
    }
  }, [location.pathname]);

  const handleNotificationClick = () => {
    setShowNotifications(!showNotifications);
    if (!showNotifications) {
      resetUnseenCount();
    }
  };

  const handleNotificationClose = () => {
    setShowNotifications(false);
    resetUnseenCount();
  };

  const handleLogout = () => {
    localStorage.removeItem("refresh");
    window.location.reload();
  };

  const navigateToProfile = () => {
    if (location.pathname.includes("/")) {
      navigate(`/${location.pathname.split("/")[1]}/my-profile`);
    }
  };

  const handleEditResume = () => {
    setPortfolio({ ...portfolio, edit_portfolio: true });
    setshowEditResume(false);
    navigate(`/cpl-applicant`);
  };

  const handleStartOver = () => {
    setPortfolio({ start_over: true });
    setshowStartOver(false);
    navigate(`/cpl-applicant`);
  };

  const isMobile = window.innerWidth <= 768;

  const handleDropdownToggle = () => {
    if (isMobile) {
      setShowDropdown((prevState) => !prevState);
    }
  };

  return (
    <div className="header">
      <div className="header-sandwich" onClick={toggleMenu}>
        <List size={24} />
      </div>

      <img
        className="header-logo"
        src={subdomainInfo?.logo_url ? subdomainInfo.logo_url : LogoKMQ}
        alt="kmq-logo"
        onClick={() => navigate("/")}
      />

      <div className="header-actions">
        <div className="header-notifications" onClick={handleNotificationClick}>
          <Bell size={34} />
          {unseenCount > 0 && <div className="notification-dot">{unseenCount}</div>}
        </div>

        <Notifications isOpen={showNotifications} onClose={handleNotificationClose} />

        <div
          className="header-profile"
          onMouseEnter={!isMobile ? () => setShowDropdown(true) : undefined}
          onMouseLeave={!isMobile ? () => setShowDropdown(false) : undefined}
          onClick={handleDropdownToggle}
        >
          <UserProfile
            name={{
              first_name: data?.first_name ?? "Administrator",
              last_name: data?.last_name ?? "",
            }}
            showText
          />
          {showDropdown && (
            <div className="dropdown-menu">
              <div className="dropdown-item" onClick={navigateToProfile}>
                My Profile
              </div>
              {portfolio &&
                portfolio.id !== "unset" &&
                !portfolio.edit_portfolio &&
                !portfolio.start_over &&
                portfolio.status === PortfolioStatusTypes.payment_pending && (
                  <>
                    <div
                      className="dropdown-item"
                      onClick={() => setshowEditResume(true)}
                    >
                      Edit Resume
                    </div>
                    <div
                      className="dropdown-item"
                      onClick={() => setshowStartOver(true)}
                    >
                      Start Over
                    </div>
                  </>
                )}
              <div
                className="dropdown-item"
                onClick={() => setLogoutModalOpen(true)}
              >
                Logout
              </div>
            </div>
          )}
        </div>
      </div>

      <AlertModal
        open={logoutModalOpen}
        onClose={() => setLogoutModalOpen(false)}
        icon={<SignOut size={56} color="#212121" />}
        title="Log out?"
        text="Are you sure you want to log out? You will be signed out of your account."
        closeText="Close"
        proceedText="Log out"
        onProceed={handleLogout}
      />

      <AlertModal
        open={showEditResume}
        onClose={() => setshowEditResume(false)}
        icon={<CheckCircle size={56} color="#212121" />}
        text="If you modify any resume fields, the previous recommendations will be erased once new ones are generated."
        proceedText="Yes, proceed"
        title="Edit your information"
        closeText="No, cancel"
        onProceed={handleEditResume}
      />

      <AlertModal
        open={showStartOver}
        onClose={() => setshowStartOver(false)}
        text="Returning to the resume upload page will erase all your current information. You'll need to restart the process. Are you sure you want to continue?"
        proceedText="Yes, proceed"
        onProceed={handleStartOver}
        title="Warning"
        closeText="No, cancel"
        icon={<WarningCircle size={56} color="#212121" />}
      />
    </div>
  );
};

export default Header;