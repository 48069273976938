import { PlusCircle, Trash, WarningCircle } from '@phosphor-icons/react';
import React, { forwardRef, useContext, useState } from 'react';
import DeleteButton from '../../UI/Button/DeleteButton/DeleteButton';
import WorkExperience, { IWorkExperience } from './components/WorkExperience/WorkExperience';
import AlertModal from '../../UI/Modals/AlertModal/AlertModal';
import { deepClone } from '../../../lib/resume-parser/deep-clone';
import { ResumeContext } from '../../../hooks/Context/ResumeContext';
import { resumeToProfile } from '../../../lib/data-formatting/resumeToProfile';
import { useMutateNormalization } from '../../../api/normalization/useQueryNormalization';
import { CurrOptionsType } from '../../../lib/resume-parser/redux/types';

interface WorkExperienceSectionProps {
  workRef?: React.RefObject<HTMLDivElement>;
  index?: number;
  currOptions: CurrOptionsType;
  setCurrOptions: React.Dispatch<React.SetStateAction<CurrOptionsType>>;
  setCurrentWorkIndex?: (index: number) => void;
  isRecommendationError?: boolean;
  handleAddWork: () => void;
  isReview?: boolean;
  portfolioSubmitted?: boolean;
}

const WorkExperienceSection = forwardRef(({
  workRef,
  index = 0,
  currOptions,
  setCurrOptions,
  setCurrentWorkIndex,
  isRecommendationError,
  handleAddWork,
  isReview = false,
  portfolioSubmitted = false,
}: WorkExperienceSectionProps, ref) => {
  const [deleteModal, setDeleteModal] = useState<number | null>(null);
  const [parsedResume, setParsedResume] = useContext(ResumeContext);
  const { mutate: postNormalization } = useMutateNormalization(setCurrOptions);

  const handleEditResume = (newWork: IWorkExperience, index: number) => {
    const newResume = deepClone(parsedResume);
    newResume.work_experience[index] = { ...newResume.work_experience[index], ...newWork };
    setParsedResume(newResume);
  };

  const handleNormalization = () => postNormalization(resumeToProfile(parsedResume));

  const handleWorkRemoval = (deleteIndex: number) => {
    const newWorkExp = parsedResume.work_experience.filter((_, i) => i !== deleteIndex);
    setParsedResume({ ...parsedResume, work_experience: newWorkExp });
    setCurrentWorkIndex?.(Math.max(deleteIndex - 1, 0));
    setDeleteModal(null);
  };

  const handleDeleteClick = (idx: number) => {
    if (parsedResume.work_experience.length > 1) {
      setDeleteModal(idx);
    }
  };

  const normalizationChoices = currOptions.jobs[index]?.normalization_choices || [];

  if (isReview) {
    return (
      <div className="edit-resume-review">
        <div className="edit-resume-work-title-review">Work history</div>
        <div ref={workRef} className="work-experience-review-page">
        {parsedResume.work_experience.map((item, idx) => (
          <div key={idx}>
            <div className="edit-resume-work-title-with-delete">
              <div className="edit-resume-work-subtitle">Work Experience {idx + 1}</div>
              {!portfolioSubmitted && (
                <DeleteButton
                  isDisabled={parsedResume.work_experience.length <= 1}
                  onClick={() => handleDeleteClick(idx)}
                />
              )}
            </div>
            <AlertModal
              open={deleteModal === idx}
              onClose={() => setDeleteModal(null)}
              onProceed={() => handleWorkRemoval(idx)}
              text={`Are you sure you want to delete ${
                item?.job_title ? `${item?.job_title} work experience` : 'this work experience'
              }?`}
              proceedText="Yes"
              closeText="No"
              title="Delete Work Experience?"
              icon={<Trash size={56} color="#212121" />}
            />
            
              <WorkExperience
                work={item}
                changeWorkExperience={(editedWork: IWorkExperience) => handleEditResume(editedWork, idx)}
                normalizationOptions={currOptions?.jobs?.[idx]?.normalization_choices || []}
                updateNormalization={handleNormalization}
                isError={isRecommendationError}
                removeJob={() => setDeleteModal(idx)}
              />
          </div>
        ))}
        </div>
        {!portfolioSubmitted && (
          <div className="edit-resume-subsection-add" onClick={handleAddWork}>
            <span className="edit-resume-subsection-add-icon">
              <PlusCircle size={32} color="#212121" />
            </span>
            <span className="edit-resume-subsection-add-text">Add work experience</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="work-experience-notification">
        <WarningCircle size={21} color="#212121" />
        Please make sure to fill all the fields in existing work experiences completely before adding a new work experience.
      </div>
      <div className="edit-resume-work-title">Work history</div>
      <div className="edit-resume-header-delete">
        <h3>Work Experience {index + 1}</h3>
        <DeleteButton
          onClick={() => handleDeleteClick(index)}
          isDisabled={parsedResume.work_experience.length <= 1}
        />
      </div>
      <div className="edit-resume-header-mandatory">*All fields are mandatory to be filled out</div>
      <WorkExperience
        work={parsedResume.work_experience[index]}
        changeWorkExperience={(editedWork: IWorkExperience) => handleEditResume(editedWork, index)}
        normalizationOptions={normalizationChoices}
        updateNormalization={handleNormalization}
        isError={isRecommendationError}
        removeJob={() => setDeleteModal(index)}
      />
      <div className="edit-resume-subsection-add" onClick={handleAddWork}>
        <span className="edit-resume-subsection-add-icon">
          <PlusCircle size={32} color="#212121" />
        </span>
        <span className="edit-resume-subsection-add-text">Add work experience</span>
      </div>
      <AlertModal
        open={deleteModal !== null}
        onClose={() => setDeleteModal(null)}
        onProceed={() => deleteModal !== null && handleWorkRemoval(deleteModal)}
        text="Are you sure you want to delete this work experience?"
        proceedText="Yes"
        closeText="No"
        title="Delete Work Experience?"
      />
    </div>
  );
});

export default WorkExperienceSection;
