import React from "react";
import ParseResume from "../app/cpl-user/parse-resume/ParseResume";
import UserProgramsRoute from "../app/cpl-user/user-programs";
import ApplicantProgramLayout from "../app/cpl-user/user-programs/program-id/ApplicantProgramLayout";
import ApplicantCourses from "../app/cpl-user/user-programs/program-id/user-courses/ApplicantCourses";
import ApplicantProgramDetails from "../app/cpl-user/user-programs/program-id/program-details/ApplicantProgramDetails";
import ApplicantCourseDetails
  from "../app/cpl-user/user-programs/program-id/user-courses/course-id/ApplicantCourseDetails";
import FAQ from "../app/faq/FAQ";
import MyProfile from "../app/cpl-user/my-profile";
import UserMarketplace from "../app/cpl-user/marketplace";
import EvidenceSubmission from "../app/cpl-user/evidence-submission/EvidenceSubmission";
import QuizApplicant from "../app/cpl-user/micro-quizzes";
import NotificationsPage from "../components/NotificationSystem/NotificationPage";

const Payment = React.lazy(() => import("../app/cpl-user/payment"));
const PaymentLayout = React.lazy(() => import("../app/cpl-user/payment/PaymentLayout"));

export const cplApplicant = [
  {
    path: '/cpl-applicant',
    element: <ParseResume/>
  },
  {
    path: '/cpl-applicant/faq',
    element: <FAQ/>
  },
  {
    path: '/cpl-applicant/notifications',
    element: <NotificationsPage />
  },
  {
    path: '/cpl-applicant/my-programs',
    element: <UserProgramsRoute/>
  },
  {
    path: '/cpl-applicant/my-programs/:program_id',
    element: <ApplicantProgramLayout/>,
    children: [
      {
        path: '/cpl-applicant/my-programs/:program_id',
        element: <ApplicantCourses/>
      },
      {
        path: '/cpl-applicant/my-programs/:program_id/details',
        element: <ApplicantProgramDetails/>
      }
    ]
  },
  {
    path: '/cpl-applicant/my-programs/:program_id/:course_code',
    element: <ApplicantCourseDetails/>
  },
  {
    path: '/cpl-applicant/my-programs/:program_id/:course_code/quiz',
    element: <QuizApplicant/>
  },
  {
    path: '/cpl-applicant/my-profile',
    element: <MyProfile/>
  },
  {
    path: '/cpl-applicant/evidence-submission',
    element: <EvidenceSubmission/>
  },
  {
    path: '/cpl-applicant/payment/:session_id',
    element: <PaymentLayout/>,
    children: [
      {
        path: '/cpl-applicant/payment/:session_id',
        element: <Payment/>
      }
    ]
  },
  {
    path: '/cpl-applicant/marketplace',
    element: <UserMarketplace/>
  },

]