import React, { useEffect, useRef, useState, useContext } from "react";
import { X } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import "./notifications.scss";
import { useNotifications } from "../../api/notifications/useNotifications";
import { UserContext } from "../../hooks/Context/UserContext";
import { PermissionType } from "../../constants/types/PermissionType";
import { useNotificationRouting } from "../../api/notifications/useNotificationRouting";
import moment from "moment";

interface NotificationsProps {
  isOpen: boolean;
  onClose: () => void;
}

const Notifications: React.FC<NotificationsProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();
  const {
    notifications,
    markNotificationsAsSeen,
    refreshNotifications
  } = useNotifications();
  const { handleNotificationClick } = useNotificationRouting();
  const [displayState, setDisplayState] = useState<Record<string, boolean>>({});
  const isMobile = window.innerWidth <= 768;
  const notificationsRef = useRef<HTMLDivElement>(null);
  const hasCalledMarkSeenRef = useRef(false);
  const hasFetchedRef = useRef(false);
  const [user] = useContext(UserContext);
  
  const getFormattedDate = (timestamp: string) => {
    return moment(timestamp).format('MMM D, YYYY [at] h:mma');
  };

  useEffect(() => {
    if (isOpen && notifications.length > 0 && !hasFetchedRef.current) {
      const initialState = notifications.reduce((acc, notification) => {
        acc[notification.id] = !notification.is_seen;
        return acc;
      }, {} as Record<string, boolean>);
      setDisplayState(initialState);
    }
  }, [isOpen, notifications]);

  useEffect(() => {
    const handleOpen = async () => {
      if (isOpen && !hasFetchedRef.current) {
        hasFetchedRef.current = true;
        await refreshNotifications();
        
        if (!hasCalledMarkSeenRef.current) {
          const unseenNotifications = notifications.filter(notification => !notification.is_seen);
          if (unseenNotifications.length > 0) {
            await markNotificationsAsSeen();
            hasCalledMarkSeenRef.current = true;
          }
        }
      }
    };

    handleOpen();

    if (!isOpen) {
      hasCalledMarkSeenRef.current = false;
      hasFetchedRef.current = false;
    }
  }, [isOpen, refreshNotifications]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationsRef.current &&
        !notificationsRef.current.contains(event.target as Node) &&
        !(event.target as Element).closest(".header-notifications")
      ) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleViewAll = () => {
    const isAdmin = user.permission_groups?.includes(PermissionType.cpl_admin) || 
                   user.permission_groups?.includes(PermissionType.cpl_super_admin);
    const basePath = isAdmin ? '/cpl-admin/notifications' : '/cpl-applicant/notifications';
    navigate(basePath);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      ref={notificationsRef}
      className={`notifications-container ${isMobile ? "mobile" : ""}`}
    >
      <div className="notifications-header">
        <h2>Notifications</h2>
        <button className="close-button" onClick={onClose}>
          <X size={24} />
        </button>
      </div>
      <div className="notifications-list">
        {notifications.length === 0 ? (
          <div className="no-notifications">You have no notifications.</div>
        ) : (
          notifications.map((notification) => {
            const showDot = displayState[notification.id];
            
            return (
              <div
                key={notification.id}
                className={`notification-item ${showDot ? "unread" : ""}`}
                onClick={() => handleNotificationClick(notification)}
              >
                {showDot && <div className="notification-dot" />}
                <div className="notification-content">
                  <p>{notification.title}</p>
                  <span className="timestamp">
                    {getFormattedDate(notification.created_at)}
                  </span>
                </div>
              </div>
            );
          })
        )}
      </div>
      {notifications.length !== 0 && <div className="notifications-footer">
        <div className="view-all" onClick={handleViewAll}>View all</div>
      </div>}
    </div>
  );
};

export default Notifications;