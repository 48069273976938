import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {emailUnsubscribeQuizURL} from "../../constants/api/email";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";

export const useGetEmailUnsubscribeQuiz = () => {

  async function getQuiz(data: {token: string}) {
    return axios.get(backendBaseUrl + emailUnsubscribeQuizURL, {
      headers: {
        'Authorization': 'Bearer ' + data.token
      }
    }).then(resp => resp.data)
  }

  return useMutation({
    mutationKey: ['get-email-unsubscribe-quiz'],
    mutationFn: getQuiz,
  });
}