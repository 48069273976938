//CertificateDate.tsx
import './certificate-date.scss';
import moment from "moment/moment";
import { useMemo, useState } from "react";
import SelectKMQ from '../../UI/Select/SelectKMQ';
import { monthsOptions, yearsOptions } from '../../../constants/resume/dates';
import ErrorKMQ from '../../UI/Error/ErrorKMQ';

const defaultTempDate = {
  month: '',
  year: '',
}

interface CertificateDateProps {
  changeCertificate: (newCertificate: any) => void;
  issue_date?: string;
  isError?: boolean;
  hideFutureDates?: boolean;
}

const CertificateDate = (props: CertificateDateProps) => {
  const {
    changeCertificate,
    issue_date,
    isError,
    hideFutureDates
  } = props;

  const [tempDate, setTempDate] = useState<{ month: string, year: string }>(defaultTempDate);

  const getMonth = (date?: string) => {
    if (date) {
      return {
        label: moment(date).format('MMMM'),
        value: moment(date).format('MMMM')
      }
    }
    return null;
  }

  const getYear = (date?: string) => {
    if (date) {
      return {
        label: moment(date).format('YYYY'),
        value: moment(date).format('YYYY')
      }
    }
    return null;
  }

  const selectedMonth = useMemo(() => {
    if (tempDate.month) {
      return {
        label: tempDate.month,
        value: tempDate.month
      }
    }
    return getMonth(issue_date);
  }, [issue_date, tempDate]);

  const selectedYear = useMemo(() => {
    if (tempDate.year) {
      return {
        label: tempDate.year,
        value: tempDate.year
      }
    }
    return getYear(issue_date);
  }, [issue_date, tempDate]);

  const changeMonth = (newValue: string) => {
    if (tempDate.year) {
      changeCertificate({
        issue_date: moment(
          `${newValue}-${tempDate.year}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
      setTempDate(defaultTempDate);
    } else if (moment(issue_date).format() === 'Invalid date') {
      setTempDate({
        month: newValue,
        year: ''
      })
    } else {
      changeCertificate({
        issue_date: moment(
          `${newValue}-${moment(issue_date).format('YYYY')}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
    }
  }

  const changeYear = (newValue: string) => {
    if (tempDate.month) {
      changeCertificate({
        issue_date: moment(
          `${tempDate.month}-${newValue}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
      setTempDate(defaultTempDate);
    } else if (moment(issue_date).format() === 'Invalid date') {
      setTempDate({
        month: '',
        year: newValue
      })
    } else {
      changeCertificate({
        issue_date: moment(
          `${moment(issue_date).format('MMMM')}-${newValue}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
    }
  }

  return (
    <div className="certificate-date">
      <SelectKMQ
        options={
          selectedYear?.value === moment().format('YYYY') && hideFutureDates
            ? monthsOptions.filter((item, index) => index < Number(moment().format('MM')))
            : monthsOptions
        }
        value={selectedMonth}
        onChange={(newMonth) => changeMonth(newMonth.value)}
        label={'Issue date'}
        placeholder={'Month'}
        error={isError && !selectedMonth}
      />
      <SelectKMQ
        options={yearsOptions}
        value={selectedYear}
        onChange={(newYear) => changeYear(newYear.value)}
        placeholder={'Year'}
        error={isError && !selectedYear}
      />
      {
        (isError && (!selectedMonth || !selectedYear)) &&
        <div className='certificate-date-error'>
          <ErrorKMQ error={"Some fields are missing or incomplete, please fill in all required information."}/>
        </div>
      }
    </div>
  );
}

export default CertificateDate;