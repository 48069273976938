import {useQuery} from "@tanstack/react-query";
import {evidenceSectionUrl, evidencesUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

interface SectionParams {
  experience_id?: string,
}

export const useQueryEvidenceSection = (params: SectionParams) => {
  const {
    experience_id
  } = params;
  const axios = useAxios();

  const handleRequest = () => {
    if (experience_id) {
      return axios.get(evidenceSectionUrl(experience_id), {})
        .then(response => response?.data ?? response);
    }
  }

  return useQuery({
    queryKey: ['evidence-section', experience_id],
    queryFn: handleRequest
  })
}
