export const postResumeParserUrl = 'resume-parsing/resume-parse';
export const postLoginUrl = 'user-management/login';
export const adminInfoUrl = 'user-management/user-profile';
export const adminsListUrl = 'core-plar-recommendation/cpl-admin';
export const loginMfaUrl = 'user-management/verification-code';
export const registerUrl = 'user-management/register';
export const forgotPasswordUrl = 'user-management/forgot-password';
export const resetPasswordUrl = 'user-management/reset-password-magic-link';
export const resetPasswordWithTokenUrl = 'user-management/reset-password';
export const identityProvidersUrl = 'openid-connect/auth-servers';
export const identityProviderUrl = 'openid-connect/auth-server';
export const identityProviderByIdUrl = (id: string) => `openid-connect/auth-server/${id}`;
export const openIDConnectLoginUrl = 'openid-connect/authenticate';



export const evidencesUrl = 'core-plar-recommendation/evidence';
export const evidenceSectionUrl = (id?: string) => `/core-plar-recommendation/experience/${id}`;
export const evidenceFeedbackUrl = 'core-plar-recommendation/evidence-feedback';
export const courseFeedbackUrl = 'core-plar-recommendation/course-recommendation-feedback';
export const assignPortfolioUrl = '/core-plar-recommendation/assign-portfolio';
export const assignPortfolioToAdminUrl = '/core-plar-recommendation/cpl-super-admin/assign-portfolio';
export const finishPortfolioReviewUrl = '/core-plar-recommendation/portfolio-review';
export const userInfoUrl = 'core-plar-recommendation/portfolio';
export const applicantsUrl = 'core-plar-recommendation/portfolio-list';
export const reportUrl = 'core-plar-recommendation/evidence-report';
export const faqUrl = 'faq/questions';
export const stripeSessionUrl = '/payments/stripe-checkout-session';
export const stripeSessionStatusUrl = '/payments/stripe-session-status';

export const paidCoursesUrl = "/course-information/courses";
export const documentRequestEndpointUrl = "document-upload/document-request";
export const documentUploadEndpointUrl = 'document-upload/document';
export const portfolioResumeEndpointUrl = '/core-plar-recommendation/portfolio/resume'
export const inactiveUsersExportUrl = '/core-plar-recommendation/inactive-cpl-user-list/csv';
export const notificationsEndpoint = '/notifications/';


export const assessmentListURL = 'assessment-engine/assessments/';


export const assessmentSubmitURL = 'assessment-engine/calculate/';


export const assessmentFinishLaterURL = 'assessment-engine/save_responses/';


export const assessmentFinishLaterGetURL = 'assessment-engine/responses/';


export const assessmentProgressGETURL = 'assessment-engine/assessments/';

export const assessmentQuestionDataGETURL = 'assessment-engine/assessment/'

export const assessmentResultGETURL = 'assessment-engine/results/';

export const subdomainInfoURL = 'user-management/platform-information';
export const termsOfUseURL = 'user-management/terms-privacy-policy';
export const landingInfoURL = 'course-information/education-institution/landing-page'
export const paidCoursesCsvReportURL = 'core-plar-recommendation/paid-courses-csv'