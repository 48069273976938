import './profile-file-upload.scss';
import LabelKMQ from "../../../../UI/Label/LabelKMQ";
import UploadIcon from "../../../../../assets/icons/UI/icons/upload.svg";
import React, {ChangeEvent, useRef} from "react";
import {useSubmitDocument} from "../../../../../api/cpl-applicant/useMutateSubmitDocument";
import FileInfo from "./FileInfo";
import {toast} from "react-toastify";
import Info from "../../../../UI/Info/Info";

export interface IAttachment {
  file: File,
  name: string,
  description: string,
  saved: boolean,
  edit: boolean,
  id?: number,
  uniqueId?: string
}

const FileUpload = ({
  onSave,
  optional,
  files,
  maxFiles
}: { files: IAttachment[], optional?: boolean, onSave: (el: any) => void, maxFiles: number }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const validFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'video/mp4', 'video/mov', 'video/quicktime', 'image/png', 'image/jpeg', 'image/jpg'];
  const {mutateAsync: submitDocument, isPending: submitDocumentPending} = useSubmitDocument();

  const generateUniqueId = () => {
    return Date.now().toString() + Math.random().toString(36).substr(2, 9);
  };

  const setFiles = (filesFist: IAttachment[]) => {
    onSave({related_documents: filesFist});
  }

  const onDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const newFile = event.dataTransfer.files[0];
    if (files.length < maxFiles && newFile && validFileTypes.includes(newFile.type)) {
      setFiles([...files, {
        file: newFile,
        name: '',
        description: '',
        saved: false,
        edit: false,
        uniqueId: generateUniqueId()
      }]);
    }
  };

  const handleAddFile = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (files.length < maxFiles && selectedFile && validFileTypes.includes(selectedFile.type)) {
      setFiles([...files, {
        file: selectedFile,
        name: '',
        description: '',
        saved: false,
        edit: false,
        uniqueId: generateUniqueId()
      }]);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  const editFile = (newFile: IAttachment) => {
    setFiles(files.map((item) => 
      item.uniqueId === newFile.uniqueId ? newFile : item
    ));
  }

  const removeFile = (removeFile: IAttachment) => {
    setFiles(files.filter((item) => 
      item.uniqueId !== removeFile.uniqueId
    ));
  }

  const saveFile = async (newFile: IAttachment) => {
    if (!newFile.description || !newFile.name) {
      toast.error('Please fill all the fields.');
      return;
    }
    try {
      const response = await submitDocument({
        title: newFile.name,
        description: newFile.description,
        file: newFile.file,
      });
      
      setFiles(files.map((item) => 
        item.uniqueId === newFile.uniqueId
          ? {...newFile, saved: true, edit: false, id: response.id}
          : item
      ));
    } catch (error) {
      console.error('Error submitting document:', error);
    }
  };

  const handleInputClick = () => {
    if (fileInputRef?.current) {
      fileInputRef.current.click();
    }
  }

  return <div>
    <div className={'profile-file-upload'}>
      <div className={'profile-file-upload-title'}>
        Supporting Documents
        {
          optional
            ? <span className={'profile-file-upload-title-optional'}>
                {' (Optional)'}
              </span>
            : null
        }
        <Info text={'Supporting Documents<br/><br/>' +
          '• Attaching supporting documents helps us verify your eligibility more efficiently. This simple step can significantly reduce processing time while strengthening your profile.<br/><br/>' +
          '• For easy reference, consider providing a descriptive file title for identification. Recommended: "Yourname_doctitle"'}/>
      </div>
      <LabelKMQ>
        Upload Attachments
      </LabelKMQ>

      <div className={'profile-file-upload-container'}
           onDrop={onDrop}
           onDragOver={(event) => event.preventDefault()}
      >
        <div className={'profile-file-upload-container-image'}
             onClick={handleInputClick}
        >
          <img
            src={UploadIcon}
            alt={'upload'}
          />
          <input
            type="file"
            onChange={handleAddFile}
            ref={fileInputRef}
            accept=".doc,.docx,.pdf,.mp4,.mov,.png,.jpeg,.jpg"
          />
        </div>
        <div className={'profile-file-upload-container-description'}>
          <div className={'profile-file-upload-container-description-title'}>
            {files.length < maxFiles ? 'Drag your files here' : 'Max number of uploads reached!'}
          </div>
          <div className={`profile-file-upload-container-description-text ${files.length === maxFiles ? 'upload-hide' : ''}`}>
            Accepted file formats: .docx, .doc, .pdf, .jpg, .png, .mp4, .mov
            <br/>
            Max files size: 50MBs | Max files: 5
          </div>
        </div>
        <div className={`profile-file-upload-container-divider ${files.length === maxFiles ? 'upload-hide' : ''}`}>
          <div className={'profile-file-upload-container-divider-line'}/>
          <div className={'profile-file-upload-container-divider-text'}>
            OR
          </div>
          <div className={'profile-file-upload-container-divider-line'}/>
        </div>
        <div className={`profile-file-upload-container-browse ${files.length === maxFiles ? 'upload-hide' : ''}`}>
        <span className={'profile-file-upload-container-browse-link'} onClick={handleInputClick}>
          Browse
        </span>
          {' your computer'}
        </div>
      </div>
    </div>

    {files.map((item, index) =>
      <div key={item.uniqueId || index}>
      <FileInfo
        file={item}
        editFile={editFile}
        onSave={() => saveFile(item)}
        onCancel={(e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation();
          removeFile(item);
        }}
        isLoading={submitDocumentPending}
      />
    </div>
    )}
  </div>
}

export default FileUpload;