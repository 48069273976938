import './file-info.scss';
import FileIcon from '../../../../../../assets/icons/UI/icons/file.svg';
import FileLoadingIcon from '../../../../../../assets/icons/UI/icons/file-saving-loading.svg';
import FileSavedIcon from '../../../../../../assets/icons/UI/icons/file-saving-done.svg';
import FileDeleteIcon from '../../../../../../assets/icons/UI/icons/delete.svg';
import InputKMQ from "../../../../../UI/Input/InputKMQ";
import ButtonKMQ from "../../../../../UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../../../../constants/ui/types/ButtonTypes";
import {IAttachment} from "../index";
import React from "react";

interface IFile {
  file: IAttachment,
  editFile: (newFile: IAttachment) => void,
  onSave: () => void,
  onCancel: (e: React.MouseEvent<HTMLInputElement>) => void,
  isLoading?: boolean
}

const FileInfo = (props: IFile) => {
  const {
    file,
    editFile,
    onSave,
    onCancel,
    isLoading
  } = props;

  const handleEditFile = (e: React.MouseEvent<HTMLInputElement>) => {
    e.stopPropagation();
    editFile({...file, edit: true});
  }

  if (file.saved && !file.edit) {
    return <div className={'file-info file-info-closed'}
                onClick={handleEditFile}>
      <div className={'file-info-header'}>
      <img
        src={FileIcon}
        alt={'file'}
        className={'file-info-header-icon'}
      />
      <div className={'file-info-header-info'}>
        <div className={'file-info-header-info-title'}>
          {file.name}
        </div>
        <div className={'file-info-header-info-text'}>
          {file.description}
        </div>
      </div>
      <div className={'file-info-header-trash'} onClick={onCancel}>
        <img
          src={FileDeleteIcon}
          alt={'remove-file'}
        />
      </div>
    </div>
    </div>
  }

  return <div className={'file-info'}>
    <div className={'file-info-header'}>
      <img
        src={FileIcon}
        alt={'file'}
        className={'file-info-header-icon'}
      />
      <div className={'file-info-header-name'}>
        {file.file.name}
      </div>
      <div className={'file-info-header-loading'}>
        <img
          src={isLoading ? FileLoadingIcon : FileSavedIcon}
          alt={'file-loading'}
        />
        <div className={'file-info-header-loading-text'}>
          {isLoading ? 'Uploading...' : 'Uploaded'}
        </div>
      </div>
    </div>
    <div className={'file-info-progress'}/>

    <InputKMQ
      label={'File Name*'}
      value={file.name}
      onChange={(e) => editFile({...file, name: e.target.value})}
      type={'text'}
      placeholder={'Enter file name'}
    />

    <div className={'file-info-large-input'}>
      <InputKMQ
        label={'File Description*'}
        value={file.description}
        onChange={(e) => editFile({...file, description: e.target.value})}
        type={'text'}
        placeholder={'Enter file description'}
      />
    </div>

    <div className={'file-info-footer'}>
      <ButtonKMQ onClick={onCancel} type={ButtonTypes.Secondary}>
        Cancel
      </ButtonKMQ>
      <ButtonKMQ onClick={onSave}>
        Save
      </ButtonKMQ>
    </div>
  </div>
}

export default FileInfo;