import { PlusCircle, Trash, WarningCircle } from '@phosphor-icons/react';
import React, { forwardRef, useContext, useState } from 'react';
import DeleteButton from '../../UI/Button/DeleteButton/DeleteButton';
import AlertModal from '../../UI/Modals/AlertModal/AlertModal';
import { deepClone } from '../../../lib/resume-parser/deep-clone';
import { ResumeContext } from '../../../hooks/Context/ResumeContext';
import Certificates, { ICertificates } from './Certification';

interface CertificatesSectionProps {
  certRef?: React.RefObject<HTMLDivElement>;
  index?: number;
  setCurrentCertIndex?: (index: number) => void;
  handleAddCertificates: () => void;
  isReview?: boolean;
  portfolioSubmitted?: boolean;
}

const CertificatesSection = forwardRef(({
  certRef,
  index = 0,
  setCurrentCertIndex,
  handleAddCertificates,
  isReview = false,
  portfolioSubmitted = false,
}: CertificatesSectionProps, ref) => {
  const [deleteModal, setDeleteModal] = useState<number | null>(null);
  const [parsedResume, setParsedResume] = useContext(ResumeContext);

  const handleEditResume = (newCert: ICertificates, index: number) => {
    const newResume = deepClone(parsedResume);
    if (!newResume.certificates) {
      newResume.certificates = [];
    }
    newResume.certificates[index] = { ...newResume.certificates[index], ...newCert };
    setParsedResume(newResume);
  };

  const handleCertRemoval = (deleteIndex: number) => {
    const newResume = deepClone(parsedResume);
    newResume.certificates = (newResume.certificates || []).filter((_, i) => i !== deleteIndex);
    setParsedResume(newResume);
    
    if (setCurrentCertIndex) {
      const newIndex = Math.max(0, Math.min(deleteIndex, newResume.certificates.length - 1));
      setCurrentCertIndex(newIndex);
    }
    setDeleteModal(null);
  };

  const handleDeleteClick = (idx: number) => {
    setDeleteModal(idx);
  };

  if (isReview) {
    const certificates = parsedResume.certificates || [];
    
    return (
      <div className="edit-resume-review">
        <div className="edit-resume-work-title-review">Certifications</div>
        <div ref={certRef}>
          {certificates.length === 0 ? (
            <div className="edit-resume-empty-state">
              <h3 className="edit-resume-empty-title">Add your professional certificates</h3>
              <p className="edit-resume-empty-description">
                Adding your certifications can help us better match you with relevant opportunities and showcase your qualifications.
              </p>
            </div>
          ) : (
            certificates.map((item, idx) => (
              <div key={idx}>
                <div className="edit-resume-work-title-with-delete">
                  <div className="edit-resume-work-subtitle">Certification {idx + 1}</div>
                  {!portfolioSubmitted && (
                    <DeleteButton
                      isDisabled={false}
                      onClick={() => handleDeleteClick(idx)}
                    />
                  )}
                </div>
                <AlertModal
                  open={deleteModal === idx}
                  onClose={() => setDeleteModal(null)}
                  onProceed={() => handleCertRemoval(idx)}
                  text={`Are you sure you want to delete ${
                    item?.name ? `${item.name} certification` : 'this certification'
                  }?`}
                  proceedText="Yes"
                  closeText="No"
                  title="Delete Certification?"
                  icon={<Trash size={56} color="#212121" />}
                />
                <Certificates
                  certificates={item}
                  changeCertificates={(editedCert: ICertificates) => handleEditResume(editedCert, idx)}
                  removeCertificates={() => setDeleteModal(idx)}
                />
              </div>
            ))
          )}
        </div>
        {!portfolioSubmitted && (
          <div className="edit-resume-subsection-add" onClick={handleAddCertificates}>
            <span className="edit-resume-subsection-add-icon">
              <PlusCircle size={32} color="#212121" />
            </span>
            <span className="edit-resume-subsection-add-text">Add certification</span>
          </div>
        )}
      </div>
    );
  }

  const certificates = parsedResume.certificates || [];
  const currentCert = certificates[index] || {};

  return (
    <div>
      <div className="edit-resume-work-title">Certifications</div>
      {certificates.length === 0 ? (
        <div className="edit-resume-empty-state">
          <h3 className="edit-resume-empty-title">Add your professional certificates</h3>
          <p className="edit-resume-empty-description">
            Adding your certifications can help us better match you with relevant opportunities and showcase your qualifications.
          </p>
        </div>
      ) : (
        <div className="work-experience-notification">
          <WarningCircle size={21} color="#212121" />
          Please make sure to fill all the required fields in existing certifications completely before adding a new certification.
        </div>
      )}
      
      {certificates.length > 0 && (
        <>
          <div className="edit-resume-header-delete">
            <h3>Certification {index + 1}</h3>
            <DeleteButton
              onClick={() => handleDeleteClick(index)}
              isDisabled={false}
            />
          </div>
          <div className="edit-resume-header-mandatory">*All fields are mandatory unless stated.</div>
          <Certificates
            certificates={currentCert}
            changeCertificates={(editedCert: ICertificates) => handleEditResume(editedCert, index)}
            removeCertificates={() => setDeleteModal(index)}
          />
        </>
      )}
      
      <div className="edit-resume-subsection-add" onClick={handleAddCertificates}>
        <span className="edit-resume-subsection-add-icon">
          <PlusCircle size={32} color="#212121" />
        </span>
        <span className="edit-resume-subsection-add-text">
          {certificates.length === 0 ? 'Add certification' : 'Add another certification'}
        </span>
      </div>
      
      <AlertModal
        open={deleteModal !== null}
        onClose={() => setDeleteModal(null)}
        onProceed={() => deleteModal !== null && handleCertRemoval(deleteModal)}
        text={`Are you sure you want to delete ${
          currentCert?.name ? `${currentCert.name} certification` : 'this certification'
        }?`}
        proceedText="Yes"
        closeText="No"
        title="Delete Certification?"
        icon={<Trash size={56} color="#212121" />}
      />
    </div>
  );
});

export default CertificatesSection;