import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {identityProvidersUrl} from "../../constants/api/endpoints";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";
import {jsonToJwt} from "../../lib/jwt/jsonToJwt";

export const useQueryIdentityProviders = () => {
  const getIdentityProviders = () => {
    return axios.get(`${backendBaseUrl}${identityProvidersUrl}`, {
      headers: {
        "Authorization": "Bearer " + jsonToJwt({}),
      }
    }).then((data) => data.data.auth_servers);
  };

  return useQuery({
    queryKey: ['identity_providers'],
    queryFn: getIdentityProviders,
  });
};