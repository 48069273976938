import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLineDown, CaretDown, FileText } from "@phosphor-icons/react";
import "./applicant-documents.scss";
import { useDocumentDownload } from "../../../../../api/admin/useDocumentDownload";
import { useQueryDocumentRequests } from "../../../../../api/admin/useQueryDocumentRequests";
import { DocumentRequest } from "../../../../../constants/types/DocumentRequest";
import DocumentRequestButton from "../../../AdminDocumentRequest/DocumentRequestButton/DocumentRequestButton";
import ButtonKMQ from "../../../../../components/UI/Button/ButtonKMQ";
import { ButtonTypes } from "../../../../../constants/ui/types/ButtonTypes";
import { PermissionType } from "../../../../../constants/types/PermissionType";
import { useQueryUserInfo } from "../../../../../api/admin/useQueryUserInfo";
import { UserContext } from "../../../../../hooks/Context/UserContext";

const ApplicantDocuments = () => {
  const { portfolio_id } = useParams<{ portfolio_id: string }>();
  const [expandedRequest, setExpandedRequest] = useState<string | null>(null);
  const [downloadingId, setDownloadingId] = useState<string | null>(null);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const { downloadDocument } = useDocumentDownload();
  const {data: userInfo, isLoading: isLoadingUser, refetch} = useQueryUserInfo({
    portfolio_id: portfolio_id,
  });

  const navigate = useNavigate();
  const [user] = useContext(UserContext);

  const {
    data: allDocumentRequests,
    isLoading,
    error,
  } = useQueryDocumentRequests({
    portfolio_id: portfolio_id || "",
  });

  const resolvedDocuments = allDocumentRequests?.filter(
    (request) => request.status === "Request resolved"
  ) || [];

  const handleDownloadAll = async () => {
    if (!portfolio_id || isDownloadingAll) return;
    
    try {
      setIsDownloadingAll(true);
      await downloadDocument({ portfolio_id });
    } catch (error) {
      console.error("Error downloading all documents:", error);
    } finally {
      setIsDownloadingAll(false);
    }
  };

  const handleDownloadSingle = async (documentId: string) => {
    if (downloadingId) return;
    
    try {
      setDownloadingId(documentId);
      await downloadDocument({ document_request_id: documentId });
    } catch (error) {
      console.error("Error downloading document:", error);
    } finally {
      setDownloadingId(null);
    }
  };

  return (
    <div className="applicant-info-documents-section">
      <section className="info-section">
        <div className="info-header">
          <h2>Requested Documents</h2>
        </div>
        {!isLoading && !error && resolvedDocuments.length > 0 && (
          <div
            className="applicant-info-documents-section-download-all"
            onClick={handleDownloadAll}
          >
            <ArrowLineDown size={20} />
            <span>{isDownloadingAll ? 'Downloading...' : 'Download all'}</span>
          </div>
        )}
      </section>
      <div className="documents-list">
        {!isLoading && resolvedDocuments.length > 0 && (
          <div className="documents-header">
            <span>Request Title</span>
            <span>Request Date</span>
            <span></span>
          </div>
        )}
        
        {isLoading ? (
          <div className="loading-text">Loading documents...</div>
        ) : error || !resolvedDocuments.length ? (
          <div className="empty-documents">
            <div className="empty-state">
              <span>You haven't requested any documents.</span>
              <ButtonKMQ
                onClick={() => navigate(`/cpl-admin/${portfolio_id}/request-document-upload`)}
                type={ButtonTypes.Tertiary}
                disabled={!userInfo?.assigned_to_me && !user?.permission_groups?.includes(PermissionType.cpl_super_admin)}
              >
                Request documents
              </ButtonKMQ>
            </div>
          </div>
        ) : (
          resolvedDocuments.map((request: DocumentRequest) => (
            <div key={request.id} className="document-item-container">
              <div className="document-item">
                <span className="document-item-title">
                  <FileText size={20} />
                  {request.title}
                </span>
                <span>{new Date(request.created_at).toLocaleDateString()}</span>
                <div className="document-item-actions">
                  <span
                    className="document-item-download"
                    onClick={() => handleDownloadSingle(request.id)}
                  >
                    {downloadingId === request.id ? 'Downloading...' : 'Download'}
                  </span>
                  <CaretDown
                    size={20}
                    className={`toggle-icon ${expandedRequest === request.id ? 'expanded' : ''}`}
                    onClick={() =>
                      setExpandedRequest(
                        expandedRequest === request.id ? null : request.id
                      )
                    }
                  />
                </div>
              </div>
              {expandedRequest === request.id && (
                <div className="request-details">
                  <div className="detail-row">
                    <span className="detail-label">Paid course</span>
                    <span className="detail-value">
                      {`${request.paid_course.course.code} ${request.paid_course.course.title}`}
                    </span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Notes to applicant</span>
                    <span className="detail-value">{request.notes}</span>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ApplicantDocuments;