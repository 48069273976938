import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {openIDConnectLoginUrl} from "../../constants/api/endpoints";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";
import {jsonToJwt} from "../../lib/jwt/jsonToJwt";

export const useOpenIDLogin = () => {
  const postOpenIDLogin = async ({state, code}: { state: string, code: string }) => {
    if (state && code) {
      const response = await axios.post(`${backendBaseUrl}${openIDConnectLoginUrl}`,
        {
          state,
          code
        }, {
          headers: {
            "Authorization": "Bearer " + jsonToJwt({}),
          },
        });
      return response.data;
    }
  };

  return useMutation({
    mutationKey: ['identity_provider_login'],
    mutationFn: postOpenIDLogin,
    retry: 1,
  });
};