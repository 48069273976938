import {IPortfolio} from "../../hooks/Context/PortfolioContext";
import moment from "moment/moment";

export const portfolioToResume = (portfolio: IPortfolio) => {
  return {
    basic_info: {
      full_name: 'portfolio.portfolio_user_name',
      email: portfolio.portfolio_email
    },
    work_experience: portfolio.jobs
      ? portfolio.jobs.map(item => {
        return {
          company: item.company,
          current_job: item.current_job,
          job_summary: item.description,
          job_title: item.title,
          location: item.location,
          normalized: item.normalization_choice,
          start_date: moment(item.start_date).format('YYYY-MM-DD'),
          end_date: item.end_date
            ? moment(item.end_date).format('YYYY-MM-DD')
            : undefined,
        }
      }) : [],
    education: portfolio.educations
      ? portfolio.educations.map(item => {
        return {
          currently_enrolled: !item.graduated,
          degree_type: item.degree_type,
          institution_name: item.institution_name,
          institution_type: item.institution_type,
          location: item.location,
          program: item.program,
          normalized: item.normalization_choice,
          start_date: moment(item.start_date).format('YYYY-MM-DD'),
          end_date: item.end_date
            ? moment(item.end_date).format('YYYY-MM-DD')
            : undefined,
        }
      }) : [],
      certificates: portfolio.certificates
    ? portfolio.certificates.map(item => {
      return {
        name: item.name,
        certificate_issuer: item.certificate_issuer,
        issue_date: item.issue_date,
        location: item.location,
        certificate_url: item.certificate_url,
        description: item.description,
      }
    }) : [],
    portfolio_id: portfolio.id
  }
}