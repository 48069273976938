import './how-it-works.scss';
import {NavBarOptions} from "../../../../../../components/UI-v2/NavBar";
import {ILandingData} from "../../../../../../constants/types/LandingData";

const HowItWorks = ({landingPageInfo}: { landingPageInfo: ILandingData }) => {
  return <div className={'how-it-works'} id={NavBarOptions.how_it_works}>
    <h2 className={'how-it-works-title'}>
      Get started in three easy steps
    </h2>

    <ol className={"how-it-works-list"}>
      <li>
        <h3 className={'how-it-works-list-title'}>
          Upload your resumé
        </h3>
        <div className={'text-body-regular how-it-works-list-text'}>
          Drag and drop your resume into our AI {landingPageInfo.cpl_term} assessment tool for a quick assessment of
          your work and education experience
        </div>
      </li>
      <li>
        <h3 className={'how-it-works-list-title'}>
          See your potential credits
        </h3>
        <div className={'text-body-regular how-it-works-list-text'}>
          Instantly view the course credits you’re potentially eligible for within your academic program of interest.
        </div>
      </li>
      <li>
        <h3 className={'how-it-works-list-title'}>
          Apply for credit approval
        </h3>
        <div className={'text-body-regular how-it-works-list-text'}>
          Pay only for the specific course credits you want evaluated and applied to your transcript. Confirmed results within 1-2 weeks.
        </div>
      </li>
    </ol>
  </div>
}

export default HowItWorks;