import React, { ChangeEvent } from 'react';
import TextArea from '../../UI/TextArea/TextArea';
import InputKMQ from '../../UI/Input/InputKMQ';
import CertificateDate from './CertificateDate';
import './certificates.scss';
import FileUpload, {IAttachment} from "./components/FileUpload";

export interface ICertificates {
  name?: string;
  certificate_issuer?: string;
  issue_date?: string;
  location?: string;
  certificate_url?: string;
  description?: string;
  related_documents?: IAttachment[]
}

interface CertificatesProps {
  certificates: ICertificates;
  changeCertificates: (certificates: ICertificates) => void;
  removeCertificates: () => void;
  isError?: boolean;
}

const Certificates: React.FC<CertificatesProps> = ({
  certificates,
  changeCertificates,
  removeCertificates,
  isError
}) => {
  const handleChange = (field: keyof ICertificates, value: string) => {
    changeCertificates({
      ...certificates,
      [field]: value,
    });
  };

  const handleDateChange = (dateFields: Partial<ICertificates>) => {
    changeCertificates({
      ...certificates,
      ...dateFields
    });
  };

  return (
    <div className="certificates">
      <div className="input-container">
        <InputKMQ
          value={certificates?.name || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange('name', e.target.value)
          }
          type="text"
          placeholder="Enter certification name"
          label="Certification Name*"
        />
      </div>

      <div className="input-container">
        <InputKMQ
          value={certificates?.certificate_issuer || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange('certificate_issuer', e.target.value)
          }
          type="text"
          placeholder="Enter certificate issuer"
          label="Certificate Issuer*"
        />
      </div>

      <CertificateDate
        changeCertificate={handleDateChange}
        issue_date={certificates?.issue_date}
        isError={isError}
        hideFutureDates
      />

      <div className="input-container">
        <InputKMQ
          value={certificates?.location || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange('location', e.target.value)
          }
          type="text"
          placeholder="Enter location"
          label="Location (Optional)"
        />
      </div>

      <div className="input-container">
        <InputKMQ
          value={certificates?.certificate_url || ''}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleChange('certificate_url', e.target.value)
          }
          type="text"
          placeholder="Enter certificate URL"
          label="Certificate URL (Optional)"
        />
      </div>

      <div className="certificates-description">
        <TextArea
          label="Certificate Description*"
          value={certificates?.description || ''}
          onChange={(e) => handleChange('description', e.target.value)}
          placeholder="Enter certificate description"
        />
      </div>

    <FileUpload
      onSave={(newCert: ICertificates) => {
        changeCertificates({
          ...certificates,
          ...newCert
        });
      }}
      files={certificates.related_documents || []}
      maxFiles={5}
    />
    </div>
  );
};

export default Certificates;