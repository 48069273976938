import './evidence-component-table.scss';
import ApproveEvidence from '../../../../assets/icons/evidences/thumbs up.svg';
import ApproveEvidenceActive from '../../../../assets/icons/evidences/thumbs_up_active.svg';
import RejectEvidence from '../../../../assets/icons/evidences/thumbs down.svg';
import RejectEvidenceActive from '../../../../assets/icons/evidences/thumbs_down_active.svg';
import {useMutateEvidenceFeedback} from "../../../../api/admin/useMutateEvidenceFeedback";
import {useEffect, useState} from "react";
import LoadMoreIcon from '../../../../assets/icons/UI/select/arrow-down.svg';
import {useQueryEvidenceSection} from "../../../../api/admin/useQueryEvidenceSection";
import Info from "../../../UI/Info/Info";
import EvidenceModal from "../../../UI/Modals/EvidenceModal";

export interface IEvidence {
  matching_score: number,
  portfolio_section: { id: string, title: string }[]
  approved: boolean,
  resume_category: string
  resume_detail: string
  resume_sentence: string,
  evidence_id: string
}

interface EvidenceTableProps {
  evidences: IEvidence[],
  stickyHeight?: number,
  canSendFeedback?: boolean
}

const EvidenceTable = (props: EvidenceTableProps) => {
  const {
    evidences,
    stickyHeight,
    canSendFeedback
  } = props;

  const {
    mutate: postEvidenceFeedback,
  } = useMutateEvidenceFeedback();

  const [showItems, setShowItems] = useState<number>(5);
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);

  const {data} = useQueryEvidenceSection({experience_id: selectedSection});

  const handleEvidenceFeedback = (evidence_id: string, approved: boolean | null) => {
    if (canSendFeedback) {
      postEvidenceFeedback({evidence_id, approved});
    }
  }

  useEffect(() => {
    if (data) {
      setShowModal(true);
    }
  }, [data])

  return <div className={'evidence-component-table-content'}>
    <tr>
      <th scope="col" style={{top: stickyHeight}}>Score %</th>
      <th scope="col" style={{top: stickyHeight}}>Referred section <Info text={'Click on any title in this section to view more detailed information.'}/></th>
      <th scope="col" style={{top: stickyHeight}}>Evidence Source</th>
      <th scope="col" style={{top: stickyHeight}}>Evidence Text</th>
      <th scope="col" style={{top: stickyHeight}}>Feedback</th>
    </tr>

    {
      evidences && evidences.slice(0, showItems).map((item: IEvidence, index: number) =>
        <tr key={index}>
          <td>
            <div className={'evidence-component-table-score'}>
              {Math.round(item.matching_score * 100)}%
            </div>
            <span
              className={`evidence-component-table-chicklet ${item.resume_category === 'job' ? 'job-chicklet' : ''} ${item.resume_category === 'education' ? 'education-chicklet' : ''} ${item.resume_category === 'certification' ? 'certification-chicklet' : ''}`}>
              {item.resume_category[0].toUpperCase() + item.resume_category.slice(1)}
            </span>
          </td>
          <td>
            {
              item.portfolio_section.map((section: { id: string, title: string }, sectionIndex: number) =>
                <div
                  key={sectionIndex}
                  dangerouslySetInnerHTML={{__html: section.title}}
                  onClick={() => setSelectedSection(section.id)}
                  className={'evidence-component-table-sentence'}
                />)
            }
          </td>
          <td>
            {item.resume_detail}
          </td>
          <td>
            {item.resume_sentence}

          </td>
          <td>
            <img
              src={item.approved ? ApproveEvidenceActive : ApproveEvidence}
              alt={'approve-evidence'}
              className={`evidence-component-table-actions evidence-component-table-actions-approve ${canSendFeedback ? '' : 'evidence-component-table-actions-disabled'}`}
              onClick={canSendFeedback ? () => handleEvidenceFeedback(item.evidence_id, item.approved ? null : true) : undefined}
            />
            <img
              // don't change to !item.approved because 'approved' field may be null
              src={item.approved === false ? RejectEvidenceActive : RejectEvidence}
              alt={'reject-evidence'}
              className={`evidence-component-table-actions evidence-component-table-actions-reject ${canSendFeedback ? '' : 'evidence-component-table-actions-disabled'}`}
              onClick={canSendFeedback ? () => handleEvidenceFeedback(item.evidence_id, item.approved === false ? null : false) : undefined}
            />
          </td>
        </tr>
      )
    }
    {
      evidences.length > showItems
        ? <tr>
        <td/><td/><td/><td/>
          <td>
            <div className={'evidence-component-table-more'} onClick={() => setShowItems(showItems + 5)}>
              <img
                src={LoadMoreIcon}
                alt={'load more'}
              />
              Load more
            </div>
          </td>
        </tr>
        : null
    }
    <EvidenceModal
      open={showModal}
      onClose={() => {
        setShowModal(false);
        setSelectedSection('');
      }}
      evidenceData={data}
    />
  </div>
}

export default EvidenceTable;