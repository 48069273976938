import { useState, useEffect, useRef, useCallback } from 'react';
import { useAxios } from '../../hooks/axios/useAxios';
import { notificationsEndpoint } from '../../constants/api/endpoints';

export interface Notification {
  id: string;
  message: string;
  created_at: string;
  is_read: boolean;
  is_seen: boolean;
  is_dismissed: boolean;
  type: string;
  title: string;
  meta_data: any;
}

interface UseNotificationsReturn {
  notifications: Notification[];
  markNotificationsAsSeen: () => Promise<void>;
  markAsRead: (notificationId: string) => Promise<void>;
  deleteAllNotifications: () => Promise<void>;
  unseenCount: number;
  resetUnseenCount: () => void;
  refreshNotifications: () => Promise<void>;
}

/**
 * Singleton object to manage global polling state across multiple hook instances
 * This prevents multiple polling intervals when the hook is used in different components
 */
const globalPollingState = {
  isPolling: false,                    // Whether polling is currently active
  lastFetchTime: 0,                    // Timestamp of last fetch
  subscribers: new Set<() => void>(),  // Set of callback functions for polling
};


export const useNotifications = (isNotificationsPage: boolean = false): UseNotificationsReturn => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unseenCount, setUnseenCount] = useState<number>(0);
  const isMounted = useRef(true);              // Track component mount state
  const axios = useAxios();
  const lastFetchRef = useRef<number>(0);      // Track last fetch timestamp for debouncing
  const initialFetchDone = useRef(false);      // Track if initial fetch has been completed

  /**
   * Calculate the number of unseen and unread notifications
   */
  const calculateUnseenCount = useCallback((notifs: Notification[]) => {
    return notifs.filter(notification => !notification.is_seen && !notification.is_read).length;
  }, []);

  // Fetch notifications from the server
  const fetchNotifications = useCallback(async (isInitialFetch: boolean = false) => {
    if (!isMounted.current) return;

    // Implement debouncing - prevent multiple calls within 1 second
    const now = Date.now();
    const minTimeBetweenFetches = 1000;

    if (now - lastFetchRef.current < minTimeBetweenFetches) {
      return;
    }

    lastFetchRef.current = now;
    
    try {
      // add read_later param for notifications main page initial load
      const config = isInitialFetch && isNotificationsPage 
        ? { params: { action: 'read_later' } }
        : {};

      const { data } = await axios.get(notificationsEndpoint, config);
      if (data?.notifications && isMounted.current) {
        setNotifications(data.notifications);
        setUnseenCount(calculateUnseenCount(data.notifications));
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }, [axios, calculateUnseenCount, isNotificationsPage]);

  /**
   * Set up global polling system - 30 seconds
   * Ensures only one polling interval exists across all hook instances
   */
  useEffect(() => {
    const setupPolling = () => {
      if (!globalPollingState.isPolling) {
        globalPollingState.isPolling = true;
        const interval = setInterval(() => {
          globalPollingState.subscribers.forEach(callback => callback());
        }, 30000);

        return () => {
          clearInterval(interval);
          globalPollingState.isPolling = false;
        };
      }
    };

    // add this instance's fetch function to global subscribers
    const fetchCallback = () => fetchNotifications(false);
    globalPollingState.subscribers.add(fetchCallback);

    const cleanup = setupPolling();

    return () => {
      globalPollingState.subscribers.delete(fetchCallback);
      if (cleanup) cleanup();
    };
  }, [fetchNotifications]);

  /**
   * Handle initial fetch and component cleanup
   */
  useEffect(() => {
    isMounted.current = true;

    if (!initialFetchDone.current) {
      fetchNotifications(true);
      initialFetchDone.current = true;
    }

    return () => {
      isMounted.current = false;
    };
  }, [fetchNotifications]);

  /**
   * Mark a single notification as read
   * Updates local state and sends request to server
   */
  const markAsRead = useCallback(async (notificationId: string) => {
    if (!isMounted.current) return;

    try {
      await axios.patch(notificationsEndpoint, {
        notification_ids: [notificationId],
        action: 'read'
      });
      
      setNotifications(prevNotifications => {
        const updatedNotifications = prevNotifications.map(notification => 
          notification.id === notificationId 
            ? { ...notification, is_read: true }
            : notification
        );
        setUnseenCount(calculateUnseenCount(updatedNotifications));
        return updatedNotifications;
      });
    } catch (error) {
      console.error('Error marking notification as read:', error);
    }
  }, [axios, calculateUnseenCount]);

  /**
   * Mark multiple notifications as seen
   * Used when opening the notifications dropdown
   */
  const markNotificationsAsSeen = useCallback(async () => {
    if (!isMounted.current) return;

    const unseenNotificationIds = notifications
      .filter(notification => !notification.is_seen)
      .map(notification => notification.id);

    if (unseenNotificationIds.length === 0) return;

    try {
      await axios.patch(notificationsEndpoint, {
        notification_ids: unseenNotificationIds,
        action: 'seen'
      });
      
      setNotifications(prevNotifications => 
        prevNotifications.map(notification => 
          unseenNotificationIds.includes(notification.id)
            ? { ...notification, is_seen: true }
            : notification
        )
      );
    } catch (error) {
      console.error('Error marking notifications as seen:', error);
    }
  }, [axios, notifications]);

  /**
   * Delete all notifications
   * Clears both local state and server data
   */
  const deleteAllNotifications = useCallback(async () => {
    if (!isMounted.current) return;

    try {
      await axios.delete(notificationsEndpoint);
      setNotifications([]);
      setUnseenCount(0);
    } catch (error) {
      console.error('Error deleting notifications:', error);
    }
  }, [axios]);

  /**
   * Reset the unseen count to zero
   * Used when closing the notifications dropdown
   */
  const resetUnseenCount = useCallback(() => {
    setUnseenCount(0);
  }, []);

  /**
   * Manually trigger a refresh of notifications
   * Used when needing to force an update
   */
  const refreshNotifications = useCallback(async () => {
    if (!isMounted.current) return;
    await fetchNotifications(false);
  }, [fetchNotifications]);

  return {
    notifications,
    markNotificationsAsSeen,
    markAsRead,
    deleteAllNotifications,
    unseenCount,
    resetUnseenCount,
    refreshNotifications,
  };
};