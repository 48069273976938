import { useLocation, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "../../hooks/Context/UserContext";
import { PermissionType } from "../../constants/types/PermissionType";

export const useNotificationRouting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useContext(UserContext);
  const isAdmin = user.permission_groups?.includes(PermissionType.cpl_admin) ||
    user.permission_groups?.includes(PermissionType.cpl_super_admin);

  const handleCplAdminApplicantPortfolio = (metadata: any, notificationId: string) => {
    const { portfolio_id } = metadata.params;
    const { tab, scroll } = metadata.query;
    let path = `/cpl-admin/${portfolio_id}`;
    if (tab) {
      path += `?tab=${tab}`;
      if (scroll && tab === 'applicant-information') {
        path += `&scroll=${scroll}`;
      }
    }
    return path;
  };

  const handleCplAdminQuizResults = (metadata: any, notificationId: string) => {
    const { portfolio_id, quiz_id, course_code, program_id } = metadata.params;
    return `/cpl-admin/${portfolio_id}/${program_id}/${course_code}`;
  };

  const handleCplApplicantQuiz = (metadata: any, notificationId: string) => {
    const { quiz_id, course_code, program_id } = metadata.params;
    return `/cpl-applicant/my-programs/${program_id}/${course_code}/quiz`;
  };

  const handleCplApplicantDocumentRequest = () => {
    return '/cpl-applicant/evidence-submission';
  };

  const handleCplApplicantPrograms = () => {
    return '/cpl-applicant/my-programs';
  };

  const handleCplAdminApplicantList = (metadata: any, notificationId: string) => {
    setUser({ ...user, notification_id: notificationId });
    
    if (location.pathname === '/cpl-admin') {
      return '/cpl-admin';
    }
    
    return '/cpl-admin';
  };

  const getRoutePath = (notification: any) => {
    const routeHandlers: Record<string, (metadata: any, notificationId: string) => string> = {
      'cpl-admin-cpl-applicant-portfolio': handleCplAdminApplicantPortfolio,
      'cpl-admin-cpl-applicant-quiz-results': handleCplAdminQuizResults,
      'cpl-admin-cpl-applicant-list': handleCplAdminApplicantList,
      'cpl-applicant-programs': handleCplApplicantPrograms,
      'cpl-applicant-quiz': handleCplApplicantQuiz,
      'cpl-applicant-document-request': handleCplApplicantDocumentRequest,
    };

    const handler = routeHandlers[notification.type];
    if (!handler) {
      console.warn(`No route handler found for notification type: ${notification.type}`);
      return null;
    }
    const path = handler(notification.meta_data, notification.id);
    return path;
  };

  const handleNotificationClick = (notification: any) => {
    const path = getRoutePath(notification);
    if (path) {
      const isAdminRoute = notification.type.startsWith('cpl-admin-');
      const isApplicantRoute = notification.type.startsWith('cpl-applicant-');
      if ((isAdminRoute && !isAdmin) || (isApplicantRoute && isAdmin)) {
        console.warn('User does not have permission to access this route');
        return;
      }
      if (location.pathname !== path) {
        navigate(path);
      }
    }
  };

  return { handleNotificationClick };
};