import './course-card.scss';
import StatusChiclet from "../../../Chiclets/StatusChiclet/StatusChiclet";
import ButtonKMQ from "../../../Button/ButtonKMQ";
import {PlarCardType} from "../PlarCard";
import ProgressChiclet from "../../../Chiclets/ProgressChiclet/ProgressChiclet";
import {ChicletTypes} from "../../../../../constants/ui/types/ChicletTypes";
import CourseCardStatus from "./CourseCardStatus";
import {ButtonTypes} from "../../../../../constants/ui/types/ButtonTypes";
import moment from "moment";
import WarningIcon from '../../../../../assets/icons/error/warning.svg';

export interface CourseCardProps {
  type?: PlarCardType,
  title: string,
  code?: string,
  match?: string
  onClick: () => void,
  buttonText: string,
  chicletText?: string,
  statusText?: string[],
  cpl_eligible?: boolean,
  payment_status: string,
  changePaymentStatus: () => void,
  hasQuiz?: boolean,
  quizDue?: string,
  viewQuiz?: () => void,
  quiz_submitted?: boolean
  active_catalogue_version?: boolean
}

const CourseCard = (props: CourseCardProps) => {
  const {
    type = PlarCardType.Not_started,
    title,
    code,
    onClick,
    buttonText,
    cpl_eligible,
    hasQuiz,
    quizDue,
    viewQuiz,
    quiz_submitted,
    active_catalogue_version,
  } = props;

  return <div className={`course-card course-card-${type}`}>
    {
      cpl_eligible
        ? <StatusChiclet
          text={window.screen.width > 420 ? 'Credit for Prior Learning Eligible' : 'CPL Eligible'}
        />
        : <ProgressChiclet
          type={ChicletTypes.Not_started}
          text={'Credit not Eligible for Prior Learning'}
        />
    }

    <CourseCardStatus {...props}/>

    {
      code ?
        <div className={'course-card-code'}>
          {code}
        </div>
        : null
    }


    <div className={'course-card-title'}>
      {title}
    </div>

    <div className={'course-card-footer'}>
      <span className={'course-card-footer-labels'}>
          {quizDue
            ? <span className={'course-card-footer-due'}>
              <span className={'course-card-footer-due-dot'}/>
              <span>
                Quiz due date: {moment(quizDue).format('MMM D, YYYY').toString()}
              </span>
          </span>
            : null
          }
        {
          !active_catalogue_version
            ? <span className={'course-card-footer-warning'}>
              <img
                src={WarningIcon}
                alt={'warning'}
              />
              <span className={'course-card-footer-warning-text'}>
                Attention required
              </span>
            </span>
            : null
        }
      </span>
      <div className={'course-card-footer-buttons'}>
        {
          ((hasQuiz || quiz_submitted) && viewQuiz)
            ? <ButtonKMQ onClick={viewQuiz} type={quiz_submitted ? ButtonTypes.Secondary : ButtonTypes.Tertiary}>
              {quiz_submitted ? 'View answers' : 'Start quiz'}
            </ButtonKMQ>
            : null
        }
        <ButtonKMQ onClick={onClick}>
          {buttonText}
        </ButtonKMQ>
      </div>
    </div>
  </div>
}

export default CourseCard;