export const defaultFileState = {
  name: "",
  size: 0,
  fileUrl: "",
  file: {},
};

export const defaultResume = {
  'basic_info': {},
  'work_experience': [
      {
          'company': '',
          'job_title': '',
          'job_summary': ''
      }
  ],
  'education': [
      {
          'program': ''
      }
  ],
  'certificates':[]
}

export const RESUME_STATE: string = 'Resume'
export const PROFILE_STATE: string = 'Profile'
export const WORK_EXPERIENCE_STATE: string = 'Work History'
export const EDUCATION_STATE: string = 'Education History'
export const REVIEW_STATE: string = 'Review'
export const CERTIFICATES_STATE: string = 'Certifications'


export const LINKEDIN_HOW_TO_DOWNLOAD_PDF: string = 'https://www.linkedin.com/help/linkedin/answer/a541960'