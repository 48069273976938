import './evidence-modal.scss';
import Modal from "react-modal";
import {Briefcase, XCircle} from "@phosphor-icons/react";
import HistorySection from "../../../../app/admin/applicants/[user_id]/ApplicantInformation/components/HistorySection";
import "../../../../app/admin/applicants/[user_id]/ApplicantInformation/applicant-documents.scss";
import "../../../../app/admin/applicants/[user_id]/ApplicantInformation/applicant-information.scss";

interface EvidenceModalProps {
  open: boolean,
  onClose: () => void,
  evidenceData: any
}

const EvidenceModal = (props: EvidenceModalProps) => {
  const {
    open,
    onClose,
    evidenceData
  } = props;
  return <Modal
    isOpen={open && evidenceData}
    onRequestClose={onClose}
    portalClassName={'evidence-modal'}
    contentLabel="Evidence Modal"
  >
    {
      evidenceData
        ? <div className={'applicant-info'}>

            <span className={'evidence-modal-close'} onClick={onClose}>
              <XCircle size={28} color="#212121"/>
            </span>

            <HistorySection
              title={evidenceData.type === "work_experience"
                ? `"${evidenceData.title}" at ${evidenceData.company}`
                : evidenceData.type === "education_experience"
                  ? `"${evidenceData.program}" at ${evidenceData.institution_name}`
                  : `${evidenceData.name}`
              }
              icon={<Briefcase size={24} weight="regular" />}
              items={evidenceData ? [evidenceData] : []}
              type={evidenceData.type === "work_experience" ? 'work'
                : evidenceData.type === "education_experience" ? 'education' : 'certificate'}
              hideIndex
            />
          </div>
        : null
    }
  </Modal>
}

export default EvidenceModal;