import React, { useContext, useEffect, useState } from 'react';
import { useNotifications } from '../../api/notifications/useNotifications';
import { useNotificationRouting } from '../../api/notifications/useNotificationRouting';
import moment from 'moment';
import './notifications-page.scss';
import Breadcrumbs from '../UI/Breadcrumbs/Breadcrumbs';
import { UserContext } from '../../hooks/Context/UserContext';
import { PermissionType } from '../../constants/types/PermissionType';

const NotificationsPage: React.FC = () => {
  const { notifications, deleteAllNotifications, markAsRead } = useNotifications(true);
  const { handleNotificationClick } = useNotificationRouting();
  const [fadeNotifications, setFadeNotifications] = useState<Set<string>>(new Set());
  const [user] = useContext(UserContext);

  const isAdmin = user.permission_groups?.includes(PermissionType.cpl_admin) || 
                 user.permission_groups?.includes(PermissionType.cpl_super_admin);
  
  const basePath = isAdmin ? '/cpl-admin' : '/cpl-applicant';

  useEffect(() => {
    const newNotifications = notifications.filter(n => !n.is_read).map(n => n.id);
    const timeoutId = setTimeout(() => {
      setFadeNotifications(new Set(newNotifications));
    }, 1200);
    return () => clearTimeout(timeoutId);
  }, [notifications]);

  const handleDismissAll = async () => {
    await deleteAllNotifications();
  };

  const handleNotificationItemClick = async (notification: any) => {
    if (!notification.is_read) {
      await markAsRead(notification.id);
    }
    handleNotificationClick(notification);
  };

  return (
    <>
    <Breadcrumbs
        content={[
          {
            label: 'My Profile',
            link: `${basePath}/my-profile`,
          },
          {
            label: `Notifications`,
            link: `${basePath}/notifications`,
          },
        ]}
      />
    <div className="notifications-page">
      <div className="notifications-header">
        <h1>Notifications</h1>
        {notifications.length > 0 && (
          <span className="dismiss-all" onClick={handleDismissAll}>
            Dismiss all
          </span>
        )}
      </div>
      <div className="notifications-page-list">
        {notifications.length === 0 ? (
          <div className="no-notifications">You have no notifications.</div>
        ) : (
          notifications.map((notification) => (
            <div
              key={notification.id}
              className={`notification-item ${!notification.is_read ? 'unread' : ''} ${
                fadeNotifications.has(notification.id) ? 'fade' : ''
              }`}
              onClick={() => handleNotificationItemClick(notification)}
            >
              {!notification.is_read && <div className="notification-dot" />}
              <div className="notification-contents">
                <p>{notification.title}</p>
                <span className="timestamp">
                  {moment(notification.created_at).format('MMM D, YYYY [at] h:mma')}
                </span>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
    </>
  );
};

export default NotificationsPage;