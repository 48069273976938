import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {emailUnsubscribeQuizURL} from "../../constants/api/email";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";

export const useEmailUnsubscribeQuiz = () => {

  async function postAssign(data: {token: string, id: string, options: string[]}) {
    return axios.post(backendBaseUrl + emailUnsubscribeQuizURL,
      {
        id: data.id,
        options: data.options
      }, {
      headers: {
        'Authorization': 'Bearer ' + data.token
      }
    })
  }

  return useMutation({
    mutationKey: ['email-unsubscribe-quiz'],
    mutationFn: postAssign,
  });
}