import {
	User,
	Briefcase,
	GraduationCap,
	ClipboardText,
	PencilSimple,
	DownloadSimple,
} from "@phosphor-icons/react";
import moment from "moment";
import { useEffect, useState } from "react";
import TextArea from "../../../../../components/UI/TextArea/TextArea";
import ButtonKMQ from "../../../../../components/UI/Button/ButtonKMQ";
import { ButtonTypes } from "../../../../../constants/ui/types/ButtonTypes";
import { useMutateUserInfo } from "../../../../../api/admin/useMutateUserInfo";
import ApplicantDocuments from "./ApplicantDocuments";
import { IPortfolio } from "../../../../../hooks/Context/PortfolioContext";
import { useResumeDownload } from "../../../../../api/admin/useResumeDownload";
import "./applicant-information.scss";
import HistorySection from "./components/HistorySection";
import { useQueryEducationInstitutionFeatures } from "../../../../../api/general/useQueryEducationInstitutionFeatures";

const ApplicantInformation = ({ data }: { data: IPortfolio }) => {
	const [notes, setNotes] = useState<string>(data.notes || "");
	const [isEditing, setIsEditing] = useState(false);
	const [tempNotes, setTempNotes] = useState<string>(data.notes || "");
	const { downloadResume } = useResumeDownload();
	const [isDownloadingResume, setIsDownloadingResume] = useState(false);
	const updatePortfolio = useMutateUserInfo();

	const [certificatesEnabled, setCertificatesEnabled] =
		useState<boolean>(false);

	const { data: featuresData } = useQueryEducationInstitutionFeatures();

	useEffect(() => {
		if (featuresData?.cpl_features?.includes("certifications")) {
			setCertificatesEnabled(true);
		}
	}, [featuresData]);

	const formatDate = (date: string) => {
		return moment(date).format("MM/DD/YYYY");
	};

	const handleEditClick = () => {
		setIsEditing(true);
		setTempNotes(notes);
	};

	const handleCancel = () => {
		setIsEditing(false);
		setTempNotes(notes);
	};

	const handleSubmit = async () => {
		try {
			await updatePortfolio.mutateAsync({
				// @ts-ignore
				portfolio_id: data.id,
				notes: tempNotes,
			});
			setNotes(tempNotes);
			setIsEditing(false);
		} catch (error) {
			console.error("Error saving notes:", error);
		}
	};

	const handleDownloadResume = async () => {
		if (!data.id || isDownloadingResume || !data.resume_available) return;

		try {
			setIsDownloadingResume(true);
			await downloadResume({
				portfolio_id: data.id,
				portfolio_name: data.portfolio_user_name,
			});
		} catch (error) {
			console.error("Error downloading resume:", error);
		} finally {
			setIsDownloadingResume(false);
		}
	};

	return (
		<div className="applicant-info">
			<section className="info-section">
				<div className="applicant-profile-section">
					<div className="info-header">
						<User size={24} weight="regular" />
						<h2>Applicant's Information</h2>
					</div>
					<span
						className={`document-item-download ${
							!data.resume_available ? "document-item-download-disabled" : ""
						}`}
						onClick={handleDownloadResume}
					>
						<DownloadSimple size={25} /> Download Resume
					</span>
				</div>
				<div className="info-content">
					<div className="experience-block">
						<div className="detail-list">
							<div className="single-line-item">
								<label>Email:</label>
								<span>{data.portfolio_email}</span>
							</div>
							<div className="single-line-item">
								<label>Account Created:</label>
								<span>
									{data.created_at ? formatDate(data.created_at) : ""}
								</span>
							</div>
						</div>
					</div>
				</div>
			</section>

			<HistorySection
				title="Work History"
				icon={<Briefcase size={24} weight="regular" />}
				items={data?.jobs || []}
				type="work"
			/>

			<HistorySection
				title="Education History"
				icon={<GraduationCap size={24} weight="regular" />}
				items={data?.educations || []}
				type="education"
			/>

			{certificatesEnabled && (
				<HistorySection
					title="Certificates"
					icon={<ClipboardText size={24} weight="regular" />}
					items={data?.certificates || []}
					type="certificate"
				/>
			)}

			<section className="info-section" id="documents">
				<hr></hr>
				<br></br>
				<h3>My Tools</h3>
				<div className="info-section-my-tools">
					<div className="info-header-notes">
						<div className="notes-section">
							<h2>Add Notes</h2>
						</div>
						{!isEditing && (
							<button
								className="edit-button"
								onClick={handleEditClick}
								aria-label="Edit notes"
							>
								<PencilSimple size={20} weight="regular" />
							</button>
						)}
					</div>
					<div className="notes-content">
						<TextArea
							value={isEditing ? tempNotes : notes}
							onChange={(e) => setTempNotes(e.target.value)}
							placeholder="Add a note"
							readOnly={!isEditing}
						/>
						{isEditing && (
							<div className="button-group">
								<ButtonKMQ onClick={handleCancel} type={ButtonTypes.Secondary}>
									Cancel
								</ButtonKMQ>
								<ButtonKMQ
									onClick={handleSubmit}
									disabled={
										!tempNotes.trim() ||
										tempNotes === notes ||
										updatePortfolio.isPending
									}
									type={ButtonTypes.Primary}
								>
									{updatePortfolio.isPending ? "Saving..." : "Submit"}
								</ButtonKMQ>
							</div>
						)}
					</div>
					<ApplicantDocuments />
				</div>
			</section>
		</div>
	);
};

export default ApplicantInformation;
