import Loading from "../../../components/UI/Loading/Loading";
import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useToken} from "../../../hooks/jwt/useToken";
import {useOpenIDLogin} from "../../../api/login/useOpenIDLogin";
import {UserContext} from "../../../hooks/Context/UserContext";
import {toast} from "react-toastify";


const OpenIDConnect = () => {
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();
  const {setTokens} = useToken();
  const {mutate, data, error} = useOpenIDLogin();

  useEffect(() => {
    const urlParams = new URLSearchParams(decodeURI(window.location.search));
    const stateParam = urlParams.get('state');
    const codeParam = urlParams.get('code');
    if (stateParam && codeParam) {
      mutate({
        state: decodeURI(stateParam),
        code: decodeURI(codeParam)
      });
    } else {
      navigate('/login');
    }
  }, [])

  useEffect(() => {
    if (error) {
      toast.error('Login failed. Please try again or use a different method.')
      navigate('/login');
      return;
    }

    if (data) {
      setTokens({
        accessToken: data?.access,
        refreshToken: data?.refresh
      })
      localStorage.setItem('refresh', data?.refresh)
      if (localStorage.getItem('portfolio_id')) {
        localStorage.removeItem('portfolio_id');
        localStorage.removeItem('marketplace_user');
        localStorage.removeItem('basic_info');
        setUser({
          ...user,
          isSoft: false
        });
        navigate('/');
      } else {
        navigate('/');
      }
    }

  }, [data, error])

  return <div>
    <Loading loading/>
  </div>

}

export default OpenIDConnect;