import { PlusCircle, Trash, WarningCircle } from '@phosphor-icons/react';
import React, { forwardRef, useContext, useState } from 'react';
import DeleteButton from '../../UI/Button/DeleteButton/DeleteButton';
import Education from './components/Education/Education';
import AlertModal from '../../UI/Modals/AlertModal/AlertModal';
import { deepClone } from '../../../lib/resume-parser/deep-clone';
import { ResumeContext } from '../../../hooks/Context/ResumeContext';
import { resumeToProfile } from '../../../lib/data-formatting/resumeToProfile';
import { useMutateNormalization } from '../../../api/normalization/useQueryNormalization';
import { ResumeEducation } from '../../../lib/resume-parser/redux/types';

interface EducationSectionProps {
  eduRef?: React.RefObject<HTMLDivElement>;
  index?: number;
  currOptions: { educations: any[] };
  setCurrOptions: React.Dispatch<React.SetStateAction<{ jobs: any[]; educations: any[] }>>;
  setCurrentEduIndex?: (index: number) => void;
  isRecommendationError?: boolean;
  handleAddEducation: () => void;
  isReview?: boolean;
  portfolioSubmitted?: boolean;
}

const EducationSection = forwardRef(({
  eduRef,
  index = 0,
  currOptions,
  setCurrOptions,
  setCurrentEduIndex,
  isRecommendationError,
  handleAddEducation,
  isReview = false,
  portfolioSubmitted = false,
}: EducationSectionProps, ref) => {
  const [deleteModal, setDeleteModal] = useState<number | null>(null);
  const [parsedResume, setParsedResume] = useContext(ResumeContext);
  const { mutate: postNormalization } = useMutateNormalization(setCurrOptions);

  const handleEditEducation = (newEducation: ResumeEducation, index: number) => {
    const newResume = deepClone(parsedResume);
    newResume.education[index] = { ...newResume.education[index], ...newEducation };
    setParsedResume(newResume);
  };

  const handleNormalization = () => postNormalization(resumeToProfile(parsedResume));

  const handleEducationRemoval = (deleteIndex: number) => {
    const newEducation = parsedResume.education.filter((_, i) => i !== deleteIndex);
    setParsedResume({ ...parsedResume, education: newEducation });
    setCurrentEduIndex?.(Math.max(deleteIndex - 1, 0));
    setDeleteModal(null);
  };

  const handleDeleteClick = (idx: number) => {
    if (parsedResume.education.length > 1) {
      setDeleteModal(idx);
    }
  };

  const normalizationChoices = currOptions.educations[index]?.normalization_choices || [];

  if (isReview) {
    return (
      <div className="edit-resume-review">
        <div className="edit-resume-education-title-review">Education history</div>
        <div ref={eduRef} className="education-review-page">
        {parsedResume.education.map((item, idx) => (
          <div key={idx}>
            <div className="edit-resume-education-title-with-delete">
              <div className="edit-resume-education-subtitle">Degree Program {idx + 1}</div>
              {!portfolioSubmitted && (
                <DeleteButton
                  isDisabled={parsedResume.education.length <= 1}
                  onClick={() => handleDeleteClick(idx)}
                />
              )}
            </div>
            <AlertModal
              open={deleteModal === idx}
              onClose={() => setDeleteModal(null)}
              onProceed={() => handleEducationRemoval(idx)}
              text={`Are you sure you want to delete this ${
                item?.program ? `${item.program} education entry` : 'education entry'
              }?`}
              proceedText="Yes"
              closeText="No"
              title="Delete Education Entry?"
              icon={<Trash size={56} color="#212121" />}
            />
            <Education
              education={item}
              changeEducation={(editedEducation: ResumeEducation) => handleEditEducation(editedEducation, idx)}
              normalizationOptions={currOptions?.educations?.[idx]?.normalization_choices || []}
              updateNormalization={handleNormalization}
              isError={isRecommendationError}
            />
          </div>
        ))}
        </div>
        {!portfolioSubmitted && (
          <div className="edit-resume-subsection-add" onClick={handleAddEducation}>
            <span className="edit-resume-subsection-add-icon">
              <PlusCircle size={32} color="#212121" />
            </span>
            <span className="edit-resume-subsection-add-text">Add Education</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      <div className="education-notification">
        <WarningCircle size={21} color="#212121" />
        Please make sure to fill all the fields in existing education experiences completely before adding a new education experience.
      </div>
      <div className="edit-resume-education-title">Education History</div>
      <div className="edit-resume-header-delete">
        <h3>Degree Program {index + 1}</h3>
        <DeleteButton
          onClick={() => handleDeleteClick(index)}
          isDisabled={parsedResume.education.length <= 1}
        />
      </div>
      <div className="edit-resume-header-mandatory">*All fields are mandatory to be filled out</div>
      <Education
        education={parsedResume.education[index]}
        changeEducation={(editedEducation: ResumeEducation) => handleEditEducation(editedEducation, index)}
        normalizationOptions={normalizationChoices}
        updateNormalization={handleNormalization}
        isError={isRecommendationError}
      />
      <div className="edit-resume-subsection-add" onClick={handleAddEducation}>
        <span className="edit-resume-subsection-add-icon">
          <PlusCircle size={32} color="#212121" />
        </span>
        <span className="edit-resume-subsection-add-text">Add Education</span>
      </div>
      <AlertModal
        open={deleteModal !== null}
        onClose={() => setDeleteModal(null)}
        onProceed={() => deleteModal !== null && handleEducationRemoval(deleteModal)}
        text="Are you sure you want to delete this education entry?"
        proceedText="Yes"
        closeText="No"
        title="Delete Education Entry?"
      />
    </div>
  );
});

export default EducationSection;
