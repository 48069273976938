import './evidence-button.scss';
import ApproveIcon from '../../../../assets/icons/UI/icons/approve.svg';
import RejectIcon from '../../../../assets/icons/UI/icons/reject.svg';

interface IEvidenceButton {
  text: string,
  onClick: () => void,
  type?: 'approve' | 'reject',
  disabled?: boolean
}

const EvidenceButton = (props: IEvidenceButton) => {
  const {
    text,
    onClick,
    type = 'approve',
    disabled
  } = props;

  return <span className={`evidence-button ${type === 'reject' ? 'evidence-button-reject' : ''} ${disabled ? 'evidence-button-disabled' : ''}`}
               onClick={disabled ? undefined : onClick}>
    {
      <img
        src={type === 'approve' ? ApproveIcon : RejectIcon}
        alt={type}
        className={'evidence-button-icon'}
      />
    }
    <span className={'evidence-button-text'}>
      {text}
    </span>
  </span>
}

export default EvidenceButton;