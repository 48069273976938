import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {emailUnsubscribeURL} from "../../constants/api/email";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";

export const useEmailSubscribe = () => {

  async function postAssign(data: {token: string}) {
    return axios.delete(backendBaseUrl + emailUnsubscribeURL, {
      headers: {
        'Authorization': 'Bearer ' + data.token
      }
    })
  }

  return useMutation({
    mutationKey: ['email-subscribe'],
    mutationFn: postAssign,
  });
}