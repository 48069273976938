import './input.scss';
import LabelKMQ from "../Label/LabelKMQ";
import {ChangeEvent, createRef, KeyboardEventHandler, ReactNode, useState} from "react";
import ErrorKMQ from "../Error/ErrorKMQ";
import {Eye, EyeSlash} from "@phosphor-icons/react";

interface InputProps {
  value: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  type: string,
  placeholder: string,
  error?: string,
  label?: string,
  icon?: ReactNode,
  passwordShowIcon?: boolean,
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>
}

const InputKMQ = (props: InputProps) => {
  const {
    value,
    onChange,
    type,
    placeholder,
    error,
    label,
    icon,
    passwordShowIcon,
    onKeyDown
  } = props;

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputRef = createRef<HTMLInputElement>();

  const handleShowPassword = () => {
    if (inputRef.current) {
      if (showPassword) {
        inputRef.current.type = 'password';
      } else {
        inputRef.current.type = 'text';
      }
    }

    setShowPassword(!showPassword);
  }

  return <div
    className={`input-container ${error ? 'input-container-error' : ''} ${icon ? 'input-container-hasIcon' : ''}`}>
    <LabelKMQ>{label}</LabelKMQ>
    <span className={'input-container-icon'}>
      {icon}
    </span>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      ref={inputRef}
      onKeyDown={onKeyDown}
      aria-label={label ? label : 'input-field'}
    />
    {
      passwordShowIcon
        ? <span
          className={'input-container-password-icon'}
          onClick={handleShowPassword}
        >
          {
            showPassword
              ? <Eye size={26} color={'#212121'}/>
              : <EyeSlash size={26} color={'#212121'}/>
          }
          </span>
        : null
    }
    <ErrorKMQ error={error}/>
  </div>
}

export default InputKMQ;