import {PropsWithChildren} from "react";

import './button.scss';
import {ButtonTypes} from "../../../constants/ui/types/ButtonTypes";

interface ButtonProps {
  type?: ButtonTypes,
  onClick: (e?: any) => void,
  disabled?: boolean,
  toggled?: boolean,
  className?: string
}

const ButtonKMQ = (props: PropsWithChildren<ButtonProps>) => {
  const {
    children,
    type = ButtonTypes.Primary,
    onClick,
    disabled,
    toggled,
    className
  } = props;

  return <button className={`btn btn-${type} ${disabled ? 'btn-disabled' : ''} 
          ${toggled ? 'btn-toggled' : ''} ${className ? className : ''}`}
                 disabled={disabled} onClick={onClick}>
    {children}
  </button>
}

export default ButtonKMQ;