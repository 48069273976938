import {useQuery} from "@tanstack/react-query";
import axios from "axios";
import {identityProviderByIdUrl} from "../../constants/api/endpoints";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";
import {jsonToJwt} from "../../lib/jwt/jsonToJwt";

export const useQueryIdentityProvider = ({id}: { id: string }) => {
  const getIdentityProvider = async () => {
    if (id) {
      const response = await axios.get(`${backendBaseUrl}${identityProviderByIdUrl(id)}`, {
        headers: {
          "Authorization": "Bearer " + jsonToJwt({}),
        }
      });
      return response.data.request_url;
    }
    return {};
  };

  return useQuery({
    queryKey: ['identity_provider', id],
    queryFn: getIdentityProvider,
  });
};